<template>
  <div class="cont_right">
    <div class="cont_num">
      <div style="overflow:hidden;width:100%;" v-if="setInfo.equcf_basis != ''">
        <div class="flexAC" style="margin: 12px 0px;">
          <div class="udi_k"></div>
          <p class="ud_t">固定配置参数</p>
        </div>
        <div class="ui_cont" >
          <p v-for="(item, index) in setInfo.equcf_basis" :key="index">{{item.name}}：{{ item.value }}{{item.unit}}</p>
        </div>
      </div>

      <div style="overflow:hidden;width:100%;" v-if="setInfo.equcf_dynamic != ''">
        <div class="flexAC" style="margin: 12px 0px;" >
          <div class="udi_k"></div>
          <p class="ud_t">动态配置参数</p>
          <div class="mic_img" @click="save">
            <i class="iconfont iconxiazai43"></i><span>保存</span>
          </div>
        </div>
        <div class="eqconfig">
          <div v-for="item in setInfo.equcf_dynamic" :key="item.index" class="item_lis">
            <p class="itemLisP">{{ item.name }}:<span>区间范围{{ item.scope }}</span></p>
            <el-input v-model="item.value" class="dtcsInput"></el-input>
            <span class="ilSpan">{{ item.unit }}</span>
          </div>
          <!-- <div v-for="item in setInfo.equcf_dynamic" :key="item.index" class="item_lis">
            <div class="flexAC" style="width: 100%;margin-bottom: 5px;">
              <p class="item_name">{{ item.name }}:</p>
              <el-input v-model="item.value" class="dtcsInput"></el-input>
              <p class="item_unit">{{ item.unit }}</p>
            </div>
            <p class="item_scope">(范围区间：{{ item.scope}})</p>
          </div> -->
        </div>
      </div>
    </div>
    <div style="width:100%;height:100%;text-align:center;" v-if="setInfo.equcf_dynamic == '' && setInfo.equcf_basis == ''">
      <img src="../../assets/img/notdata.png" style="width:150px"/>
      <p>暂无配置参数</p>
    </div>
  </div>
</template>

<script>
import { getEquipList, updateConfig } from '@/http/api/equip'

export default {
  props: ['equDeatil'],
  data () {
    return {
      setInfo: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      getEquipList({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.setInfo = res.data.data[0]
        } else {
          this.setInfo = ''
        }
      })
    },
    save () {
      if (this.testArr()) {
        const params = {
          equid: this.equDeatil.equid,
          equcf_dynamic: this.setInfo.equcf_dynamic
        }
        updateConfig(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.$emit('jbFresh')
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error('请输入区间范围的值')
      }
    },
    testArr () {
      let af = true
      this.setInfo.equcf_dynamic.forEach(item => {
        if (!this.test(item)) {
          af = false
        }
      })
      return af 
    },
    test (item) {
      let val = false
      if (item.value < item.minvle) {
        val = false
      } else if (item.value > item.maxvle) {
        val = false
      } else {
        val = true
      }
      return val
    }
  }
}
</script>

<style scoped>
.cont_right {
  width: 100%;
}
.cont_num {
  width: 100%;
  height: 100%;
  padding-top: 20px;
}
.ui_cont, .eqconfig {
  display: flex;
  flex-flow: wrap;
}
.ui_cont p, .item_lis {
  width: 50%;
  font-size: 14px;
  font-family: 'PingFang SC';
  color: #FFFFFF;
  opacity: 1;
  margin: 0px 0px 15px;
}
.item_lis{
  display: flex;
  flex-flow: wrap;
  position: relative;
  padding-right: 10px;
  box-sizing: border-box;
}
.item_lis >>> .el-input__inner {
  background: rgba(136,172,252,0.16);
  border: none;
  color: #A5B7CC;
  height: 30px;
  line-height: 30px;
  width: 100%;
}
.itemLisP span{
  font-size: 10px;
  color: #A5B7CC;
  margin-left: 10px;
}
.ilSpan{
  position: absolute;
  right: 20px;
  top: 50%;
  color: #A5B7CC;
}
.dtcsInput {
  width: 100%;
} 
.item_unit {
  width: 10%;
}
.item_scope {
  position: absolute;
  top: 30px;
  color: #A5B7CC;
  font-size: 12px;
}
.mic_img {
  margin-left: 20px;
  color: #FFAD42;
  cursor: pointer;
  border: 1px solid #FFAD42;
  border-radius: 4px;
}
.udi_k {
  width: 4px;
  height: 18px;
  background: #196DF7;
  opacity: 1;
}
.ud_t {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
</style>
