<template>
  <!-- 参数统计 -->
  <div class="para_cont">
    <div v-show="haveFire" style="width:100%;height:100%;" ref="Smoke"></div>
    <div v-show="!haveFire" style="width:100%;height:100%;text-align:center;">
      <img src="../../assets/img/notdata.png" style="width:150px"/>
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
var echarts = require('echarts')
import { parameterStatis } from '@/http/api/work'

export default {
  props: ['equDeatil'],
  data () {
    return {
      haveFire: false,
      smokeX: [],
      smokeY1: [],
      smokeY2: [],
      smokeY3: [],
      key: [],
      keyVaule: [],
    }
  },
  mounted () {
    this.getSmokeEchart()
  },
  created () {
    // console.log(this.equDeatil)
  },
  methods: {
    getSmokeEchart(){
      parameterStatis({equid: this.equDeatil.equid}).then(res => {
        if (res.status === '1') {
          this.haveFire = true
          let _result = res.data
          for(let i= 0; i<_result.length; i++){
              this.smokeX.push(_result[i].accordtime)
              this.smokeY1.push(_result[i].alarm)
              this.smokeY2.push(_result[i].fault)
              this.smokeY3.push(_result[i].tives)
          }
          this.smokeEchart()
        }else{
          this.smokeX = []
          this.smokeY1 = []
          this.smokeY2 = []
          this.smokeY3 = []
          this.haveFire = false
        }
      }) 
    },
    smokeEchart(){
      let _smoke = echarts.init(this.$refs.Smoke)
      var max = Math.max(...this.smokeY1)
      if (max > 5) {
        max = Math.max(...this.smokeY1)
      } else {
        max = 5
      }
      let option = {
        color: ['#FF6242','#4295FF','#33D162'],
        grid: {
          top: '18%',
          bottom: '0%',
          left: '1%',
          right: '3%',
          containLabel: true
        },
        legend: {
          right: 0,
          textStyle: { color:'#A5B7CC' },
          data: ['告警趋势', '故障趋势', '误报趋势']
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(136, 172, 252, 0.16)',
          borderColor: 'rgba(136, 172, 252, 0.16)',
          textStyle: { color: '#FFFFFF' }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { color:'#A5B7CC' },
          data: this.smokeX
        },
        yAxis: {
          type: 'value',
          max: max,
          axisLabel: { color: '#A5B7CC' },
          splitLine: {
            lineStyle: {
              color: '#eee',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          }
        },
        series: [
          {
            name: '告警趋势',
            type: 'line',
            symbol: "none",
            smooth: true,
            data: this.smokeY1,
            areaStyle: { opacity: 0.3 }
          },
          {
            name: '故障趋势',
            type: 'line',
            symbol: "none",
            smooth: true,
            data: this.smokeY2,
            areaStyle: { opacity: 0.3 }
          },
          {
            name: '误报趋势',
            type: 'line',
            symbol: "none",
            smooth: true,
            data: this.smokeY3,
            areaStyle: { opacity: 0.3 }
          }
        ]
      }
      _smoke.setOption(option);
      setTimeout( ()=> {
          _smoke.resize();
      },10)
    },
  }
}
</script>

<style scoped>
.para_cont {
  width: 100%;
  height: 300px;
  position: relative;
  margin-top: 30px;
}
</style>
