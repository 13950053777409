<template>
<!-- 客服- 业务处理 -->
  <div class="business_content">
    <div class="box100">
      <!-- 业务首页 -->
      <div class="business_list box100" v-if="showOther()">
        <div class="business_tab flexCB">
          <div class="bus_btn">
            <div class="busBtn btn_Refresh" @click="getTypeNum"><i class="el-icon-refresh-right"></i>刷新</div>
          </div>
          <div class="bus_tabs flexC">
            <div class="tabItem" v-for="tab in tabs" :key="tab.type" :class="tabActive === tab.type? 'tab' + tab.type:''"
              @click="tabChange(tab)">
              <p class="tabInfo">
                <span style="line-height: 60px;">{{ tab.name }}</span>
                <span class="tabNum" :class="{Num1:tab.type==='1', Num2:tab.type==='2', Num3:tab.type==='3', Num4:tab.type==='4'}">{{ tab.num }}</span>
              </p>
            </div>
          </div>
          <div class="bus_btn flexCE">
            <div class="busBtn btn_history" @click="HandleExport">故障导出</div>
            <div class="busBtn btn_history" @click="dealHistory">处理记录</div>
          </div>
        </div>
        <!-- 告警列表  -->
        <div class="business_info flexCB" v-if="notUnit">
          <div class="busInfo_left">
            <div class="flexAC"><div class="udi_k"></div><p class="ud_t">告警单位 ( {{ companyList.length }} )</p></div>
            <div class="unitList" :class="{ur1:tabActive==='1', ur2:tabActive==='2', ur3:tabActive==='3', ur4:tabActive==='4'}">
              <div class="unitItem flexCB" v-for="list in companyList" :key="list.index" :class="unitId === list.companyid? 'unitActive':''" @click="unitChange(list)">
                <div class="utLeft flexCB">
                  <p><img :src="list.companyimage" style="border-radius: 4px;" :onerror="errorImg"/> </p>
                  <div class="leInfo">
                    <p style="margin-bottom: 10px;">{{ list.companyname }}</p>
                    <div class="flexCB"><p>告警时间</p><p class="alaTime">{{ list.ectptime }}</p></div>
                  </div>
                </div>
                <div class="utRig">{{ list.num }}</div>
              </div>
            </div>
          </div>
          <div class="busInfo_right">
            <!-- 单位信息 -->
            <div class="busInfo_rTop flexCB">
              <div class="busRtL">
                <div class="flexCB busIP">
                  <p style="width: 60%;">单位名称: {{ companyInfo.companyname }}</p>
                  <p style="width: 40%;">统一信用码: {{ companyInfo.companycode }}</p>
                </div>
                <div class="flexCB busIP">
                  <p style="width: 60%;">行业性质: {{ companyInfo.industryname }}</p>
                  <p style="width: 40%;">单位等级: {{ companyInfo.companylevelname }}</p>
                </div>
                <p class="busIP">辖区消防队: {{ companyInfo.firename }}</p>
                <p>单位地址: {{ companyInfo.companyress }}</p>
              </div>
              <div class="busRtM">
                <div class="flexC"><p class="txtRig">消控室</p><p class="txtTip">{{ companyInfo.companyphone }}</p></div>
                <div class="flexC"><p class="txtRig">安全管理人</p><p class="txtTip">{{ companyInfo.companyuser }}</p></div>
                <div class="flexC"><p class="txtRig">安全负责人</p><p class="txtTip">{{ companyInfo.securityadmin }}</p></div>
              </div>
              <div class="busRtR">
                <p class="alaNum">告警总数: {{ unitAlaNum }}</p>
                <el-row class="alaTypeNum">
                  <el-col :span = '6' v-for="item in unitAlarmNum" :key="item.index">
                    <p :class="'color' + item.type">{{ item.num }}</p><p>{{ item.name }}</p>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="busInfo_rFot">
              <AnAlarm :unitId="unitId" :type="tabActive" :Refresh="Refresh" @showEquDetail="showEquDetail" @showAlarmDetail="showAlarmDetail"></AnAlarm>
            </div>
          </div>
        </div>
        <!-- 无数据  -->
        <div class="noData" v-if="!notUnit">
          <img src="../../assets/img/notdata.png"/>
          <p>暂无数据</p>
        </div>
      </div>

      <!-- 处理记录 -->
      <div class="historyList box100" v-if="historyShow">
        <History @historyBack="historyBack" @hisHandDetail="hisHandDetail"></History>
      </div>

      <!-- 设备详情  -->
      <div class="detailBox box100" v-if="showDetail">
        <!-- 设备详情  -->
        <ProDetail v-if="equipIdList(typeId)" :equDeatil="equDeatil" @goback="goback" ></ProDetail>
        <!--  视频联动  -->
        <Monitor v-if="typeId === '1'" :equDeatil="equDeatil"  @goback="goback"></Monitor>
        <!--  萤石云可视对讲  -->
        <Visual v-if="typeId === '2'" :equDeatil="equDeatil"  @goback="goback"></Visual>
        <!--  AI摄像头  -->
        <Aicamera v-if="typeId === '20'" :equDeatil="equDeatil"  @goback="goback"></Aicamera>
        <!--  智能断路器  -->
        <Breaker v-if="typeId === '21'" :equDeatil="equDeatil"  @goback="goback"></Breaker>
      </div>
    </div>

    <!-- 告警详情-弹框 -->
    <el-dialog :visible.sync="alarmDetailShow" class="alarmDetailDialog alarmDialog hdBg" width="1200px" :close-on-click-modal="false">
      <AlarmDetail :alarmKey="alarmKey"
                   @openImg="openImg"
                   @customerDealShow="customerDealShow"
                   @customerTel="customerTel"
                   @busGzDeal="busGzDeal"
                   @ysDealShow="ysDealShow">
      </AlarmDetail>
    </el-dialog>

    <!-- 告警详情 弹窗 AI放大图片 -->
    <el-dialog title="抓拍图片" :visible.sync="showBigImg" center width="1000px" class="hdBg hdTb" style="z-index: 999;">
      <div class="bigImgBox">
        <div class="sizeBox" @mousewheel.prevent="rollImg">
          <img :src="bigImgUrl" alt="" class="img" ref="imgDiv" :onerror="notImg" @mousedown="ImgMove"/>
        </div>
      </div>
    </el-dialog>

    <!--  处理-弹框  -->
    <el-dialog title="处理" :visible.sync="customerDealWith" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <CusDealWith :alarmInfo="alarmInfo" @dealClose="dealClose" @busDealSuccess="busDealSuccess"></CusDealWith>
    </el-dialog>

    <!-- 延时处理 - 弹框 -->
    <el-dialog title="延时处理" :visible.sync="ysDealDialog" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Delayed :alarmInfo="alarmInfo" @delayedClose="delayedClose" @delayedSuccess="delayedSuccess"></Delayed>
    </el-dialog>
  </div>

</template>

<script>
import { busTypeNum, busUnitList, busUnitInfo, faultExport } from '@/http/api/business'
import { getEquipList } from '@/http/api/equip'
import AnAlarm from '@/components/customer/anAlarm.vue'
import History from '@/components/customer/history.vue'
import CusDealWith from '@/components/customer/dealWith.vue'
import Delayed from '@/components/customer/delayed.vue'
import ProDetail from '@/components/work/proDetail.vue'
import Monitor from '@/components/work/monitor.vue'
import Visual from '@/components/work/visual.vue'
import Aicamera from '@/components/work/Aicamera.vue'
import Breaker from '@/components/work/breaker'
import AlarmDetail from '@/components/home/alarmDetail'
export default {
  name: 'business',
  props: ['messageInfo'],
  components: {
    AnAlarm,
    History,
    ProDetail,// 设备详情
    Monitor,  // 设备详情-视频联动
    Visual,   // 设备详情-萤石云可视对讲
    Aicamera, // 设备详情-AI摄像头
    Breaker,  // 设备详情-智能断路器
    AlarmDetail,
    CusDealWith,
    Delayed
  },
  data () {
    return {
      tabs: [],
      tabActive: '1',
      companyList: [],
      notUnit: false,
      unitId: '',
      historyShow: false,
      companyInfo: '',
      unitAlaNum: 0,
      errorImg: 'this.src="' + require('../../assets/img/unit_default.png') + '"',
      unitAlarmNum: [],
      showDetail: false,
      equDeatil: '',
      typeId: '',
      alarmDetailShow: false,
      alarmKey: '',
      customerDealWith: false,
      alarmInfo: '',
      ysDealDialog: false,
      showBigImg: false,
      notImg:  'this.src="'+ require('@/assets/img/imgError.png') +'"',
      bigImgUrl: '',
      Refresh: false, // 刷新
    }
  },
  watch: {
    messageInfo (val) {
      this.getTypeNum()
    }
  },
  mounted () {
    this.getTypeNum()
  },
  methods: {
    /* 告警类型总数 */
    getTypeNum () {
      busTypeNum().then(res => {
        if (res.status === '1') {
          this.tabs = res.data
          this.tabActive = this.tabs[0].type
          this.getCompanyList()
        }
      })
    },
    /* tab-切换 */
    tabChange (item) {
      this.tabActive = item.type
      this.getCompanyList()
    },
    /* 单位列表 */
    getCompanyList () {
      busUnitList({ type: this.tabActive }).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            this.notUnit = true
            this.companyList = res.data
            this.unitId = res.data[0].companyid
            this.getCompanyInfo()
          } else {
            this.notUnit = false
            this.companyList = []
          }
        } else {
          this.notUnit = false
          this.companyList = []
        }
      })
    },
    /* 单位切换 */
    unitChange (item) {
      this.unitId = item.companyid
      this.getCompanyInfo()
    },
    /* 单位信息 */
    getCompanyInfo () {
      busUnitInfo({ companyid: this.unitId }).then(res => {
        if (res.status === '1') {
          this.companyInfo = res.data[0]
        }
      })
      busTypeNum({ companyid: this.unitId }).then(res => {
        if (res.status === '1') {
          this.unitAlarmNum = res.data
          this.getAllNum()
        }
      })
    },
    getAllNum () {
      let unitNum = Number(this.unitAlarmNum[0].num) + Number(this.unitAlarmNum[1].num) + Number(this.unitAlarmNum[2].num) + Number(this.unitAlarmNum[3].num)
      this.unitAlaNum = unitNum
    },
    /* 展示-处理记录 */
    dealHistory () {
      this.historyShow = true
    },
    /* 返回-处理记录 */
    historyBack () {
      this.historyShow = false
    },
    /* 详情-(处理记录) */
    hisHandDetail (res) {
      this.alarmKey = res
      this.alarmDetailShow = true
    },
    showOther () {
      if (this.showDetail === true || this.historyShow === true) { return false }
      else { return  true }
    },
    equipIdList(val) { // 设备类型id组合
      var arr = ['1', '2', '20', '21']
      var isShow = false
      if (arr.indexOf(val) == -1) {
        isShow = true
      } else {
        isShow = false
      }
      return isShow
    },
    /* 设备详情-展示 */
    showEquDetail (row) {
      getEquipList({ equid: row.equid,companyid: this.unitId }).then(res => {
        if (res.status === '1') {
          this.equDeatil = res.data.data[0]
          console.log(res)
          this.typeId = row.equtype
          this.showDetail = true
        }
      })
    },
    /* 设备详情-关闭 */
    goback () {
      this.showDetail = false
    },
    /* 告警详情-展示 */
    showAlarmDetail (res) {
      this.alarmKey = res
      this.alarmDetailShow = true
    },
    /* 处理-(火警-隐患)-展示 */
    customerDealShow (res) {
      this.customerDealWith = true
      this.alarmInfo = res
    },
    /* 处理-(火警-隐患)-取消 */
    dealClose () {
      this.customerDealWith = false
    },
    /* 处理-(火警-隐患)-提交 */
    busDealSuccess () {
      this.customerDealWith = false
      this.alarmDetailShow = false
      this.getTypeNum() //刷新
    },
    /* 电核 */
    customerTel () {
      this.alarmDetailShow = false
      this.Refresh = !this.Refresh
    },
    /* 处理-故障 */
    busGzDeal () {
      this.alarmDetailShow = false
      this.getTypeNum() //刷新
    },
    /* 延时处理-隐患 -展示 */
    ysDealShow (res) {
      this.ysDealDialog = true
      this.alarmInfo = res
    },
    /* 延时处理-隐患 - 取消*/
    delayedClose () {
      this.ysDealDialog = false
    },
    /* 延时处理-隐患 - 提交*/
    delayedSuccess () {
      this.ysDealDialog = false
      this.alarmDetailShow = false
      this.getTypeNum() //刷新
    },
    /* 故障导出 */
    HandleExport () {
      faultExport().then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          window.open(res.data.filepath)
        } else {
          this.$message.error(res.message)
        }
      })
    },

    /* Ai摄像 */
    openImg (res) {
      this.showBigImg = true
      this.bigImgUrl = res
      this.$nextTick(() => {
        this.$refs.imgDiv.style.left = '0px'
        this.$refs.imgDiv.style.top = '0px'
        this.$refs.imgDiv.style.zoom = "100%"
      })
    },
    rollImg (event) {
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100
      zoom += event.wheelDelta / 12
      if (zoom >= 80 && zoom <500) {
        this.$refs.imgDiv.style.zoom = zoom + "%"
      }
      return false
    },
    ImgMove (e) {
      e.preventDefault()
      var left = document.querySelector('.sizeBox')
      var img = document.querySelector('.img')
      var x = e.pageX - img.offsetLeft
      var y = e.pageY - img.offsetTop
      left.addEventListener('mousemove', move)
      function move(e) {
        img.style.left = e.pageX - x + 'px'
        img.style.top = e.pageY - y + 'px'
      }
      img.addEventListener('mouseup', function() {
        left.removeEventListener('mousemove', move)
      })
      left.addEventListener('mouseout', function() {
        left.removeEventListener('mousemove', move)
      })
    }
  }
}
</script>

<style scoped>
.business_content{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 70px 0px 0px;
  box-sizing: border-box;
}
.business_list,.historyList{
  padding: 20px 40px;
  box-sizing: border-box;
}
.detailBox{

  overflow-y: scroll;
  scrollbar-width: none;
}
.detailBox::-webkit-scrollbar {
  width: 0px;
}
.business_tab{
  width: 100%;
  height: 60px;
  position: relative;
}
.bus_tabs{width: 70%;position: relative;}
.bus_btn{width: 15%;}
.tabItem{
  width: 200px;
  height: 60px;
  background: rgba(136, 172, 252, 0.16);
  margin: 0px 4px;
  position: relative;
  text-align: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
/*.tab1{background: #FF4C4C;}*/
/*.tab2{background: #FFAD42;}*/
/*.tab3{background: #D930EC;}*/
/*.tab4{background: #3476F3;}*/
.tabNum{
  position: absolute;
  top: 10px;
  font-size: 12px;
  padding: 0px 5px;
  box-sizing: border-box;
  border-radius: 10px;
}
.tab1, .Num1{background: #FF4C4C;}
.tab2, .Num2{background: #FFAD42;}
.tab3, .Num3{background: #D930EC;}
.tab4, .Num4{background: #3476F3;}
.tab1 .Num1{background: #fff;color: #FF4C4C;}
.tab2 .Num2{background: #fff;color: #FFAD42;}
.tab3 .Num3{background: #fff;color: #D930EC;}
.tab4 .Num4{background: #fff;color: #3476F3;}
.tabItem:first-child{
  border-radius: 30px 0px 0px 30px;
}
.tabItem:last-child{
  border-radius: 0px 30px 30px 0px;
}
.busBtn{
  width: 120px;
  height: 60px;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}
.btn_history{
  color: #FF944C;
  margin-left: 20px;
}
.noData{
  text-align: center;
  background: rgba(136, 172, 252, 0.16);
  padding-top: 120px;
  box-sizing: border-box;
}
.business_info,.noData{
  width: 100%;
  height: calc(100% - 90px);
  margin-top: 30px;
  position: relative;
}
.busInfo_left{
  width: 360px;
  height: 100%;
  position: relative;
  background: rgba(6, 26, 52, 0.4);
  padding: 15px 10px;
  box-sizing: border-box;
}
.unitList{
  width: 100%;
  height: 93%;
  margin-top: 20px;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.unitList::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.unitItem{
  width: 100%;
  height: 70px;
  border-bottom: 1px solid rgba(136, 172, 252, 0.16);
  box-sizing: border-box;
  cursor: pointer;
}
.utLeft{
  width: 80%;
  height: 70px;
  padding: 0 10px;
  color: #A5B7CC;
}
.utLeft img{
  width: 50px;
  height: 50px;
}
.leInfo{
  width: calc(100% - 60px);
  margin-left: 10px;
}
.utRig{
  width: 20%;
  line-height: 70px;
  font-size: 30px;
  text-align: center;
}
.ur1 .unitItem .utRig{color: #FF4C4C;}
.ur2 .unitItem .utRig{color: #FFAD42;}
.ur3 .unitItem .utRig{color: #D930EC;}
.ur4 .unitItem .utRig{color: #3476F3;}
.ur1 .unitActive .utRig{background: #FF4C4C;color: #fff;}
.ur2 .unitActive .utRig{background: #FFAD42;color: #fff;}
.ur3 .unitActive .utRig{background: #D930EC;color: #fff;}
.ur4 .unitActive .utRig{background: #3476F3;color: #fff;}
.unitActive .utLeft{background: rgba(136, 172, 252, 0.16);color: #fff;}
.alaTime{
  background: rgba(136, 172, 252, 0.2);
  padding: 3px 5px;
}
.unitActive .alaTime{
  color: #FFAD42;
}
.busInfo_right{
  width: calc(100% - 380px);
  height: 100%;
  margin-left: 20px;
  position: relative;
}
.busInfo_rTop{
  width: 100%;
  height: 170px;
  position: relative;
  border: 1px solid rgba(66, 238, 255, 0.4);
  box-sizing: border-box;
  background: rgba(6, 26, 52, 0.4);
  border-radius: 8px;
  padding: 15px 20px;
}
.busRtL,.busRtM,.busRtR{
  width: 50%;
  height: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  font-size: 14px;
}
.busRtM{
  width: 21%;
  border-left: 1px solid rgba(136, 172, 252, 0.16);
  border-right: 1px solid rgba(136, 172, 252, 0.16);
}
.busRtR{width: 29%;}
.busRtM div,.busIP{margin-bottom: 18px;}
.busRtM div:last-child{margin-bottom: 0px;}
.ud_t{
  font-size: 15px;
  margin-left: 5px;
}
.txtRig{
  width: 30%;
  text-align: right;
  margin-right: 10px;
}
.txtTip{
  width: 70%;
  height: 32px;
  line-height: 32px;
  color: #FF944C;
  background: rgba(136, 172, 252, 0.16);
  padding: 0px 10px;
  box-sizing: border-box;
  border-radius: 4px;
}
.alaNum{
  width: 100%;
  font-size: 16px;
  border-bottom: 1px solid rgba(136, 172, 252, 0.16);
  text-align: center;
  padding: 15px 0px;
  box-sizing: border-box;
}
.alaTypeNum{
  text-align: center;
  padding: 20px 0px;
  box-sizing: border-box;
  color: #A5B7CC;
  font-size: 12px;
}
.color1{color: #FF4C4C;font-size: 26px;}
.color2{color: #FFAD42;font-size: 26px;}
.color3{color: #D930EC;font-size: 26px;}
.color4{color: #3476F3;font-size: 26px;}
.busInfo_rFot{
  width: 100%;
  height: calc(100% - 180px);
  margin-top: 10px;
  border: 1px solid rgba(66, 238, 255, 0.4);
  box-sizing: border-box;
  border-radius: 8px;
}
</style>
