<template>
  <!-- 故障告警 -->
  <div class="alarm_box">
    <div class="flexCB" >
      <div>
        <el-input  placeholder="请输入搜索内容" class="contInput" v-model="ectpname"></el-input>
        <el-select v-model="ectptype" placeholder="告警类型" class="utG setSe">
          <el-option
						v-for="item in ectptypeOption"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
        </el-select>
        <el-date-picker
          v-model="date"
          type="daterange"
          class="utF"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
        <el-button class="resetbtn" @click="handleReset">重置</el-button>
      </div>
    </div>
    <div class="alarm_table hidTable">
      <el-table
        :data="alarmList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="状态"
          width="100px"
          prop="ectpwttl">
          <template slot-scope="scope">
					  <span v-if="scope.row.ectpwttl == 1">未逾期</span>
					  <span v-if="scope.row.ectpwttl == 2">已逾期</span>
					</template>
        </el-table-column>
        <el-table-column
          label="告警编号"
          prop="ectpid">
          <template slot-scope="scope">
            <span class="online" @click="goAlarmDetail(scope.row)" >{{scope.row.ectpid}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="告警设备"
          prop="equname">
        </el-table-column>
        <el-table-column
          label="告警类型"
          prop="ectptype">
          <template slot-scope="scope">
					  <span v-if="scope.row.ectptype == 1">火警信息</span>
					  <span v-if="scope.row.ectptype == 2">故障</span>
					  <span v-if="scope.row.ectptype == 3">隐患</span>
					  <span v-if="scope.row.ectptype == 4">其他</span>
					</template>
        </el-table-column>
        <el-table-column
          label="告警内容"
          prop="ectpmark"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="设备地址"
          show-overflow-tooltip
          prop="adderss">
        </el-table-column>
        <el-table-column
          label="告警时间"
          prop="ectptime">
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="indexPage">
    </el-pagination>
  </div>
</template>

<script>
import { alarmDetails } from '@/http/api/work'

export default {
  props: ['equDeatil', 'type'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      date: null,
      alarmList: [],
      ectpname: '',
      ectptype: '',
      ectptypeOption: [
        {label:'全部',value:'0'},
        {label:'报警',value:'1'},
        {label:'故障',value:'2'},
        {label:'隐患',value:'3'},
        {label:'其他',value:'4'}
      ],
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      let stime = ''
      let etime = ''
      if (this.date != null) {
        stime = this.date[0]
        etime = this.date[1]
      }
      const params = {
        type: this.type,
        equid: this.equDeatil.equid,
        ectptype: this.ectptype,
        ectpname: this.ectpname,
        ectpstime: stime,
        ectpetime: etime,
        page_index: this.page,
        page_size: this.size,
      }
      alarmDetails(params).then(res => {
        if (res.status === '1') {
          this.alarmList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.alarmList = []
          this.total = 0
        }
      })
    },
    goAlarmDetail (row) {
      // this.$emit('showAlarm', row)
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.ectpname = ''
      this.ectptype = ''
      this.date = null
      this.init()
    }
  }
}
</script>

<style scoped>
.hidTable {
  height: 500px;
}
.utF {
  margin: 0 16px;
}
.online {
  color: #42EEFF;
  cursor: pointer;
}
.alarm_box  >>> .el-range-editor.el-input__inner {
  padding: 0 10px;
}
.alarm_box  >>>.el-range-editor .el-range-input {
  color: #A5B7CC;
}
</style>
