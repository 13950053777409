<template>
  <!-- 视频监控 -->
  <div class="hls_cont">
    <div class="rig_title">
      <i class="el-icon-caret-right" style="margin:0 10px;"></i>
      <p>{{equDeatil.equname}}</p>
      <span class="back" @click="goBack" >返回</span>
    </div>
    <div class="hls_container">
      <div class="hls_video">
        <video ref="videoElement" id="videoElement" controls autoplay muted style="width: 100%; height:100%"></video>
      </div>
      <div class="ezu_info">
        <div class="flexSC">
          <div class="ud_left">
            <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
              <div class="udi_k"></div>
              <p class="ud_t">设备基本信息</p>
            </div>
            <p class="ud_c">单位名称：{{ equDeatil.companyname }}</p>
            <p class="ud_c">设备名称：{{ equDeatil.equname }}</p>
            <p class="ud_c">设备编号：{{ equDeatil.equimei }}</p>
            <p class="ud_c">安装位置：{{ equDeatil.adderss }}</p>
            <p class="ud_c">安全管理人：{{ equDeatil.username }}</p>
            <p class="ud_c">电话：{{ equDeatil.userphone }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js'
import flvjs from 'flv.js'
import $ from 'jquery'
import { equCurrent } from '@/http/api/equip'
export default {
  props: ['equDeatil'],
  data () {
    return {
      hlsPlayer: null,
      flvPlayer: null,
      spurl: ''
    }
  },
  mounted () {
    equCurrent({ equid: this.equDeatil.equid }).then(res => {
      if (res.status === '1') {
        this.spurl = res.data.video_token
        this.getHlsVideo()
      }
    })
  },
  created () {
    // console.log(this.equDeatil.equip_port)
  },
  methods: {
    getHlsVideo () {
      if(this.spurl.indexOf('hls') > 0 ){ /* Hls */
        if (Hls.isSupported()) {
          let videoBox = this.$refs.videoElement
          this.hlsPlayer = new Hls()
          this.hlsPlayer.loadSource(this.spurl)
          this.hlsPlayer.attachMedia(videoBox)
          this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
            this.$refs.videoElement.play()
          })
        } else {
          console.log('不支持的hls格式')
        }
      } else if (this.spurl.indexOf('flv') > 0){ /* Flv */
        if (flvjs.isSupported()) {
          let videBox = this.$refs.videoElement
          this.flvPlayer = flvjs.createPlayer({
            type: 'flv',
            isLive: true,
            hasAudio: false,
            hasVideo: true,
            url: this.spurl
          })
          this.flvPlayer.attachMediaElement(videBox)
          this.flvPlayer.load()
          this.flvPlayer.play()
        } else {
          console.log('不支持flv格式')
        }
      }
    },
    goBack () {
      this.$emit('goback')
      $('#videoElement').empty()
      if(this.spurl.indexOf('hls') > 0 ){ /* Hls */
        this.hlsPlayer = null
      } else {
        this.flvPlayer = null
      }
    }
  }
}
</script>

<style scoped>
.hls_cont {
  width: 100%;
  height: 100%;
  padding: 20px 40px 0 40px;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  overflow: auto;
}
.hls_container {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  padding-top: 30px;
  box-sizing: border-box;
  /*position: absolute;*/
  /*top: 60px;*/
}
.hls_video {
  width: 80%;
  height: 650px;
}
.ezu_info {
  width: 20%;
  margin-left: 40px;
}
.udi_k {
  width: 4px;
  height: 18px;
  background: #196DF7;
  opacity: 1;
}
.ud_t {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
.ud_c {
  font-size: 16px;
  font-family: 'PingFang SC';
  /* font-weight: bold; */
  line-height: 23px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 10px;
}
</style>
