<template>
  <div style="width: 100%;height: 100%;padding: 20px 40px 10px 40px;box-sizing: border-box;position: relative;overflow: hidden;">
    <div class="box100" v-if="!showDetail">
      <div class="rig_title">
        <i class="el-icon-caret-right" style="margin:0 10px;"></i>
        <p>{{equDeatil.equname}}</p>
        <span class="back" @click="goBack">返回</span>
      </div>
      <div class="ai_box">
        <div class="ai_left">
          <div class="ai_video">
            <div id="video-container"></div>
          </div>
          <div class="ai_pic">
            <div class="ai_btn">
              <div class="btn_one"><p class="one_top">今日抓拍</p><p class="one_bottom">{{ TodayNumber }}</p></div>
              <div class="btn_two"  @click="goHistory">历史抓拍</div>
            </div>
            <div class="ai_photo" ref="TodayBox">
              <div class="box10 type_contains" v-show="havePhoto">
                <div class="typeBtnL" v-show="isPage" @click="arrowClick('left')">
                  <p class="el-icon-arrow-left"></p>
                </div>
                <div class="typeCont">
                  <el-carousel class="typeChange" arrow="never" indicator-position="none" :autoplay="false" ref="AiTodayShow">
                    <el-carousel-item v-for="(item, i) in pageList" :key="i">
                      <div class="scroll_page">
                        <div class="ToItem" v-for="list in item" :key="list.index">
                          <div class="toItemBox" @click="openTodayImg(list)">
                            <img :src="list.img" alt="" :onerror="notImg" />
                            <div class="ToInfo" :class="{To1:list.type === '1',To2:list.type ==='2',To3:list.type ==='3', To4:list.type ==='4'}">
                              <p>{{ list.name }}</p><p>{{ list.time }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class="typeBtnR" v-show="isPage" @click="arrowClick('right')">
                  <p class="el-icon-arrow-right"></p>
                </div>
              </div>
              <div v-show="!havePhoto" class="no_pic">
                <p>今日暂无抓拍图片</p>
              </div>
            </div>
          </div>
        </div>
        <div class="ai_right">
          <div class="ai_info flexSC">
            <div class="ud_left">
              <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
                <div class="udi_k"></div>
                <p class="ud_t">设备基本信息</p>
              </div>
              <p class="ud_c">单位名称：{{ equDeatil.companyname }}</p>
              <p class="ud_c">安装位置：{{ equDeatil.adderss }}</p>
              <div class="flexCB">
                <p class="ud_c">设备编号：{{ equDeatil.equimei }}</p>
                <p class="ud_c" style="padding-left:100px;">设备名称：{{ equDeatil.equname }}</p>
              </div>
              <div class="flexCB">
                <p class="ud_c">安全管理人：{{ equDeatil.username }}</p>
                <p class="ud_c">电话：{{ equDeatil.userphone }}</p>
              </div>
            </div>
          </div>
          <div class="ai_alarm">
            <div class="ud_left">
              <!-- 告警查询 -->
              <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
                <div class="udi_k"></div>
                <p class="ud_t">告警信息统计</p>
              </div>
              <div class="alarm_cont">
                <div class="alarm_one">
                  <span>今日总告警：{{ todayNum }}</span>
                </div>
                <div class="alarm_two">
                  <span>历史总告警：{{ historyNum }}</span>
                </div>
              </div>
              <!-- 参数统计 -->
              <div class="aiErt">
                <div v-show="haveFire" style="width:100%;height:100%;" ref="AICharts"></div>
                <div v-show="!haveFire" style="width:100%;height:100%;text-align:center;">
                  <img src="../../assets/img/notdata.png" style="width:150px"/>
                  <p>暂无数据</p>
                </div>
              </div>
              <!-- 数据统计 -->
              <div class="alarm_box">
                <div class="ala_item alaI1" v-for="(item,index) in aiequList" :key="index" :class="'alaI'+ item.type">
                  <img v-if="item.type === '1'" src="../../assets/img/fire.png"/>
                  <img v-if="item.type === '2'" src="../../assets/img/hidden.png"/>
                  <img v-if="item.type === '3'" src="../../assets/img/fault.png"/>
                  <img v-if="item.type === '4'" src="../../assets/img/other.png"/>
                  <div class="claInfo">
                    <p class="claName">{{item.name}}</p>
                    <p>今日告警：{{item.today}}</p>
                    <p>历史告警：{{item.history}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box100" v-if="showDetail">
      <CameraHistory @goHide="goHide" @openImg="openImg" :equDeatil="equDeatil"></CameraHistory>
    </div>

    <el-dialog title="今日抓拍" :visible.sync="showBig" center width="1000px" class="hdBg hdTb">
      <div class="bigImgBox">
        <div class="sizeBox" @mousewheel.prevent="rollImg"><img :src="showUrl" alt="" class="img" ref="imgDiv" :onerror="notImg" @mousedown="move"/></div>
        <div class="imgTip" :class="{To1:bigInfo.type === '1',To2:bigInfo.type ==='2',To3:bigInfo.type ==='3', To4:bigInfo.type ==='4'}">
          <p>{{ bigInfo.name }}</p>
          <p>{{ bigInfo.time }}</p>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="历史抓拍" :visible.sync="showBigHistory" center width="1000px" class="hdBg hdTb">
      <div class="bigImgBox">
        <div class="sizeBox" @mousewheel.prevent="rollImg"><img :src="showUrl" alt="" class="img" ref="imgDiv" :onerror="notImg" @mousedown="move"/></div>
        <div class="imgTip" :class="{To1:bigInfo.type === '1',To2:bigInfo.type ==='2',To3:bigInfo.type ==='3', To4:bigInfo.type ==='4'}">
          <p>{{ bigInfo.alatype }}</p>
          <p>{{ bigInfo.alatime }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EZUIKit from 'ezuikit-js'
var echarts = require('echarts')
import { aialarmInfo, aiequStatis, aimeterStatis, aitodayImg } from '@/http/api/unit'
import CameraHistory from '@/components/work/cameraHistory.vue'
import { equCurrent } from '@/http/api/equip'
import $ from 'jquery'
export default {
  props: ['equDeatil'],
  components: {
    CameraHistory
  },
  data () {
    return {
      showDetail: false,
      havePhoto: false,
      haveFire: false,
      smokeX: [],
      smokeY1: [],
      smokeY2: [],
      smokeY3: [],
      smokeY4: [],
      key: [],
      keyVaule: [],
      aiequList: [],
      typeArr: [],
      width: 0,
      widthLength: 0,
      isPage: false,
      pageList: [],
      Zsize: 1,
      selectIndex: '',
      snapList: [],
      todayNum: 0,
      historyNum: 0,
      notImg: 'this.src="'+ require('../../assets/img/imgError.png') +'"',
      showBig: false,
      showUrl: [],
      showBigHistory: false,
      TodayNumber: 0,
      bigInfo: ''
    }
  },
  computed: {
    msgState () {
      return this.$store.getters.msgState
    }
  },
  watch: {
    msgState (newV, oldV) {
      console.log(newV)
      console.log(oldV)
      this.init()
    }
  },
  mounted () {
    this.width = this.$refs.TodayBox.offsetWidth
    // console.log(this.width)
    this.getVideo()
    this.getSmokeEchart()
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 告警 数量查询 查询
      aialarmInfo({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.todayNum = res.data.today
          this.historyNum = res.data.history
        }
      })
      // 数据 类别 统计
      aiequStatis({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.aiequList = res.data
        } else {
          this.aiequList = []
        }
      })
      aitodayImg({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          if (res.data.imglist.length > 0) {
            this.havePhoto = true
            this.snapList = res.data.imglist
            this.TodayNumber = res.data.today
            this.getSnap()
          } else {
            this.havePhoto = false
          }
        }
      })
    },
    getSnap () {
      this.widthLength = (this.width / 220).toFixed(0)
      // console.log(this.widthLength)
      if (this.snapList.length > this.widthLength) {
        this.isPage = true
      } else {
        this.isPage = false
      }
      this.pageFun()
    },
    pageFun () {
      this.Zsize = Number((this.width / 220).toFixed(0))
      this.pageList = this.chunk(this.snapList, Number(this.Zsize))
      console.log(this.pageList)
    },
    // 按照大小均分数组
    chunk (arr, size) {
      var arr2 = []
      for (var i = 0; i < arr.length; i = i + size) {
        arr2.push(arr.slice(i, i + size))
      }
      return arr2
    },
    getSmokeEchart(){
      this.smokeX = []
      this.smokeY1 = []
      this.smokeY2 = []
      this.smokeY3 = []
      this.smokeY4 = []
      aimeterStatis({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.haveFire = true
          let _result = res.data
          for(let i= 0; i<_result.length; i++){
            this.smokeX.push(_result[i].times)
            this.smokeY1.push(_result[i].type1)
            this.smokeY2.push(_result[i].type2)
            this.smokeY3.push(_result[i].type3)
            this.smokeY4.push(_result[i].type4)
          }
          this.smokeEchart()
        }else{
          this.smokeX = []
          this.smokeY1 = []
          this.smokeY2 = []
          this.smokeY3 = []
          this.smokeY4 = []
          this.haveFire = false
        }
      })
    },
    smokeEchart(){
      let _smoke = echarts.init(this.$refs.AICharts)
      // var max = Math.max(...this.smokeY1)
      // if (max > 5) {
      //   max = Math.max(...this.smokeY1)
      // } else {
      //   max = 5
      // }
      let option = {
        color: ['#FF4C4C','#D930EC','#FFAD42', '#3476F3'],
        grid: {
          top: '18%',
          bottom: '0%',
          left: '1%',
          right: '3%',
          containLabel: true
        },
        // legend: {
        //   right: 0,
        //   textStyle: { color:'#A5B7CC' },
        //   data: ['告警趋势', '故障趋势', '误报趋势']
        // },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(136, 172, 252, 0.16)',
          borderColor: 'rgba(136, 172, 252, 0.16)',
          textStyle: { color: '#FFFFFF' }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { color:'#A5B7CC' },
          data: this.smokeX
        },
        yAxis: {
          type: 'value',
          // max: max,
          axisLabel: { color: '#A5B7CC' },
          splitLine: {
            lineStyle: {
              color: '#eee',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          }
        },
        series: [
          {
            name: '火警信息',
            type: 'line',
            symbol: "none",
            smooth: true,
            data: this.smokeY1,
            areaStyle: { opacity: 0.3 }
          },
          {
            name: '隐患',
            type: 'line',
            symbol: "none",
            smooth: true,
            data: this.smokeY2,
            areaStyle: { opacity: 0.3 }
          },
          {
            name: '故障',
            type: 'line',
            symbol: "none",
            smooth: true,
            data: this.smokeY3,
            areaStyle: { opacity: 0.3 }
          },
          {
            name: '其他',
            type: 'line',
            symbol: "none",
            smooth: true,
            data: this.smokeY4,
            areaStyle: { opacity: 0.3 }
          }
        ]
      }
      _smoke.setOption(option);
      setTimeout( ()=> {
        _smoke.resize();
      },10)
    },
    goHistory () {
      this.showDetail = true
    },
    goBack () {
      $('#video-container').empty()
      this.$emit('goback')
    },
    goHide () {
      this.showDetail = false
      this.getVideo()
      this.getSmokeEchart()
    },
    getVideo () {
      // 查询流地址
      equCurrent({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.player =  new EZUIKit.EZUIKitPlayer({
            autoplay: true,
            id: "video-container",
            accessToken: res.data.video_token,
            url: res.data.equip_port,
            // template: 'voice', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
            header: ['capturePicture', 'save', 'zoom'], // 如果templete参数不为simple,该字段将被覆盖
            footer: ['hd', 'fullScreen'], // 如果template参数不为simple,该字段将被覆盖
            // plugin: ['talk'],
            audio: 1,
            // width: 840,
            height: 504
          })
        }
      })
    },
    arrowClick (val){
      if (val === 'right') {
        this.$refs.AiTodayShow.next()
      } else {
        this.$refs.AiTodayShow.prev()
      }
    },
    openTodayImg (item) {
      this.showBig = true
      this.showUrl = item.img
      this.bigInfo = item
      // console.log(this.bigInfo)
      this.$refs.imgDiv.style.left = '0px'
      this.$refs.imgDiv.style.top = '0px'
      this.$refs.imgDiv.style.zoom = "100%"
    },
    openImg (res) {
      this.showBigHistory = true
      this.showUrl = res.alaimg
      this.bigInfo = res
      // console.log(this.showUrl)
      this.$refs.imgDiv.style.left = '0px'
      this.$refs.imgDiv.style.top = '0px'
      this.$refs.imgDiv.style.zoom = "100%"
    },
    rollImg (event) {
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100
      zoom += event.wheelDelta / 12
      if (zoom >= 80 && zoom <500) {
        this.$refs.imgDiv.style.zoom = zoom + "%"
      }
      return false
    },
    move (e) {
      e.preventDefault()
      var left = document.querySelector('.sizeBox')
      var img = document.querySelector('.img')
      var x = e.pageX - img.offsetLeft
      var y = e.pageY - img.offsetTop
      left.addEventListener('mousemove', move)
      function move(e) {
        img.style.left = e.pageX - x + 'px'
        img.style.top = e.pageY - y + 'px'
      }
      img.addEventListener('mouseup', function() {
        left.removeEventListener('mousemove', move)
      })
      left.addEventListener('mouseout', function() {
        left.removeEventListener('mousemove', move)
      })
    }
  }
}
</script>

<style scoped>
.box100 {
  width: 100%;
  height: 100%;
  /*padding: 20px 40px 20px 40px;*/
  box-sizing: border-box;
  position: relative;
}

.ai_box {
  width: 100%;
  /*height: 100%;*/
  height: calc(100% - 30px);
  position: relative;
  padding-top: 20px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
}
.ai_left {
  width: 66%;
  height: 100%;
  position: relative;
}
.ai_right {
  width: 33%;
  box-sizing: border-box;
  height: 100%;
  position: relative;
}
.ai_video {
  width: 100%;
  height: 600px;
  /* background: #FFAD42; */
  opacity: 1;
  border-radius: 4px;
}
.ai_pic {
  width: 100%;
  padding-top: 12px;
  display: flex;
  box-sizing: border-box;
  height: calc(100% - 600px);
}
.ai_btn {
  width: 80px;
  height: 100%;
}
.btn_one {
  width: 80px;
  height: 70%;
  background: #196DF7;
  opacity: 1;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.one_top {
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-family: 'PingFang SC';
  font-weight: 400;
  line-height: 20px;
  color: #FFFFFF;
  opacity: 1;
}
.one_bottom {
  width: 15px;
  height: 20px;
  font-size: 14px;
  font-family: 'PingFang SC';
  font-weight: 400;
  line-height: 20px;
  color: #FFFFFF;
  opacity: 1;
}
.btn_two {
  width: 80px;
  height: 23%;
  background: rgba(136, 172, 252, 0.16);
  opacity: 1;
  border-radius: 4px;
  margin-top: 10px;
  text-align: center;
  color: #FFAD42;
  line-height: 40px;
  font-weight: 400;
  cursor: pointer;
}
.ai_photo {
  width: 100%;
  /*height: 100%;*/
  background: #061A34;
  border: 1px solid rgba(66, 238, 255, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 4px;
  margin-left: 30px;
}
.type_contains{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(1, 5, 13, 0.4);
  position: relative;
}
.typeBtnL,.typeBtnR{
  width: 40px;
  height: 100%;
  background: rgba(6, 26, 52, 0.9);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 10;
}
.typeBtnL{left: 0}
.typeBtnR{right: 0}
.typeBtnL p,.typeBtnR p{
  font-size: 39px;
  color: #fff;
}
.typeCont{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px 0px;
  box-sizing: border-box;
}
.typeChange{
  width: 100%;
  height: 175px;
}
.typeChange >>> .el-carousel__container{
  height: 175px;
}
.scroll_page{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: start;
}
.ToItem{
  width: 220px;
  height: 100%;
  position: relative;
}
.toItemBox{
  width: 210px;
  height: 100%;
  margin: 0px 5px;
  box-sizing: border-box;
  position: relative;
  background-image: url("../../assets/img/imgError.png");
  background-size: 100% 100%;
  border: none;
}
.ToItem img{
  width: 100%;
  height: 100%;
}
.ToInfo{
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 2px 10px;
  box-sizing: border-box;
  line-height: 18px;
  font-size: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.To1{color: #FF4C4C;}
.To2{color: #D930EC;}
.To3{color: #FFAD42;}
.To4{color: #3476F3;}
.no_pic {
  font-size: 14px;
  font-family: 'PingFang SC';
  font-weight: 400;
  color: #FFFFFF;
  opacity: 1;
  text-align: center;
  line-height: 200px;
  letter-spacing: 5px;
}
.ai_info {
  width: 100%;
  height: 220px;
  background: #061A34;
  border: 1px solid rgba(66, 238, 255, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
}
.ai_alarm {
  width: 100%;
  /*height: 569px;*/
  height: calc(100% - 240px);
  background: #061A34;
  border: 1px solid rgba(66, 238, 255, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.ud_left {
  /*padding: 20px 0 0 20px;*/
}
.udi_k {
  width: 4px;
  height: 18px;
  background: #196DF7;
  opacity: 1;
}
.ud_t {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
.ud_c {
  font-size: 16px;
  font-family: 'PingFang SC';
  line-height: 23px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 10px;
}
.alarm_cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.alarm_one, .alarm_two {
  width: 48%;
  height: 50px;
  background: rgba(136, 172, 252, 0.16);
  border: 1px solid #A5B7CC;
  opacity: 1;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
  color: #FFFFFF;
  box-sizing: border-box;
}
.aiErt {
  width: 100%;
  height: 150px;
  padding-top: 10px;
}
.alarm_box {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.ala_item{
  width: 48%;
  height: 110px;
  margin-bottom: 15px;
  background: rgba(0, 0, 0, 0.2);
  padding: 15px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.alaI1{
  border: 1px solid #FF4C4C;
  color: #FF4C4C;
}
.alaI2{
  border: 1px solid #D930EC;
  color: #D930EC;
  margin-left: 20px;
}
.alaI3{
  border: 1px solid #FFAD42;
  color: #FFAD42;
}
.alaI4{
  border: 1px solid #3476F3;
  color: #3476F3;
  margin-left: 20px;
}
.claInfo{
  width: calc(100% - 60px);
  padding-left: 20px;
  box-sizing: border-box;
  line-height: 22px;
  font-size: 14px;
}
.claName{
  border-bottom: 1px solid;
  padding-bottom: 6px;
  margin-bottom: 6px;
  box-sizing: border-box;
}
.hdTb >>> .el-dialog__body{
  padding: 30px;
}
.bigImgBox{
  width: 100%;
  height: 550px;
  position: relative;
}
.sizeBox{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  float: left;
  text-align: center;
}
.bigImgBox img{
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 5px;
  left: 7px;
  max-width: 200%;
  max-height: 200%;
  cursor: move;
}
.imgTip{
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 14px;
}
</style>
