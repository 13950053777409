// 单位概况
import { get, post, put, deletefn } from '@/http/http'

// 单位信息
export const getcomDetails = p => get('/v1/comdetails/getcomdetails', p)

// 类型查询
export const getEquipmentType = p => get('/v1/comdetails/getdetailstype', p)

// 告警统计
export const alarmStatis = p => get('/v1/comdetails/alarmstatis', p)

// 参数统计
export const parameterStatis = p => get('/v1/comdetails/parameterstatis', p)

// 关联设备
export const assocEquinfo = p => get('/v1/comdetails/assocequinfo', p)

// 告警统计详情
export const alarmDetails = p => get('/v1/comdetails/alarmdetails', p)

// 告警信息
export const getAlarmInfo = p => get('/v1/comdetails/getalarminfo', p)

// DTU关联设备
export const DTUassocequInfo = p => get('/v1/comdetails/dtuassocequinfo', p)

// DTU关联设备查询
export const getDTUassoc = p => get('/v1/comdetails/getdtuassoc', p)

// 智能断路器合闸
export const breakerOpen = p => post('/v1/circuitequ/opencircuitequ', p)

// 智能断路器操作日志
export const breakerJournal = p => post('/v1/circuitequ/getvalueoper', p)

// 智能断路器操作人员
export const operator = p => post('/v1/circuitequ/getuserinfo', p)

// 智能断路器 线路下拉
export const xlDown = p => post('/v1/circuitequ/lineinfo', p)

// 智能断路器 电量统计
export const Elequantity = p => post('/v1/circuitequ/getelectricity', p)

// 智能断路器 参数统计类型
export const paramType = p => post('/v1/circuitequ/typeinfo', p)

// 智能断路器 参数统计
export const paramTjEch = p => post('/v1/circuitequ/getparameter', p)
