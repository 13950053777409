<template>
<!-- 摄像历史抓拍 -->
  <div class="camera_cont">
    <div class="rig_title">
      <i class="el-icon-caret-right" style="margin:0 10px;"></i>
      <p>{{equDeatil.equname}} > 历史抓拍</p><span class="back"  @click="goback">返回</span>
    </div>
    <div class="camera_box">
      <div class="cam_left">
        <div class="flexAC"><div class="udi_k"></div><p class="ud_t">历史抓拍统计</p></div>
        <div class="cl_census">
          <p class="clcp">今日总告警：{{alarmList.today}}</p>
          <p class="clcp">历史总告警：{{alarmList.history}}</p>
        </div>
        <div class="cl_alarm">
          <div class="ala_item alaI1" v-for="(item,index) in aiequList" :key="index" :class="'alaI'+ item.type">
            <img v-if="item.type === '1'" src="../../assets/img/fire.png"/>
            <img v-if="item.type === '2'" src="../../assets/img/hidden.png"/>
            <img v-if="item.type === '3'" src="../../assets/img/fault.png"/>
            <img v-if="item.type === '4'" src="../../assets/img/other.png"/>
            <div class="claInfo">
              <p class="claName">{{item.name}}</p>
              <p>今日告警：{{item.today}}</p>
              <p>历史告警：{{item.history}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cam_right">
        <div class="flexAC"><div class="udi_k"></div><p class="ud_t">历史抓拍图片</p></div>
        <div class="camR_top alarm_box flexAC">
          <el-select v-model="alatype" placeholder="设备状态" multiple class="setMore">
            <el-option
              v-for="item in alarmType"
              :key="item.index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          <el-date-picker
            v-model="date"
            type="daterange"
            class="utF setSe"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <el-button class="searchbtn"  @click="handleSearch">搜索</el-button>
          <el-button class="resetbtn" @click="handleReset">重置</el-button>
        </div>
        <div class="cam_rList">
          <div class="cam_table" v-show="notData">
            <div class="listItem" v-for="item in imgList" :key="item.index" :class="{ImgItem1:item.type === '1',ImgItem2:item.type ==='2',ImgItem3:item.type ==='3', ImgItem4:item.type ==='4'}">
              <div class="itemBox" @click="openBigImg(item)">
                <img :src="item.alaimg" alt="" :onerror="notImg"/>
                <div class="listInfo"><p>{{ item.alatype }}</p><p>{{ item.alatime }}</p></div>
              </div>
            </div>
          </div>
          <div class="cam_table" v-show="!notData">
            <p style="width:100%; text-align: center;">暂无数据</p>
          </div>
          <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[15]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="indexPage" style="margin-top: 20px;">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { aialarmInfo, aiequStatis, aimeterStatis, aihistoryImg } from '@/http/api/unit'
import { equFunction } from '@/http/api/equip'

export default {
  props: ['equDeatil'],
  name: 'cameraHistory',
  data () {
    return {
      alarmType: [],
      alatype: '',
      date: null,
      page: 1,
      size: 15,
      total: 0,
      imgList: [],
      alarmList: [],
      aiequList: [],
      notImg:  'this.src="'+ require('../../assets/img/imgError.png') +'"',
      notData: false
    }
  },
  created () {
    this.getInit()
    this.init()
    // console.log(this.equDeatil)
  },
  methods: {
    init () {
      // 告警查询
      aialarmInfo({equid: this.equDeatil.equid}).then(res => {
        if (res.status === '1') {
          this.alarmList = res.data
        } else {
          this.alarmList = []
        }
      })
      // 数据统计
      aiequStatis({equid: this.equDeatil.equid}).then(res => {
        if (res.status === '1') {
          this.aiequList = res.data
        } else {
          this.aiequList = []
        }
      })
    },
    getInit () {
      // 设备状态下拉
      equFunction().then(res => {
        if (res.status === '1') {
          this.alarmType = res.data
        } else {
          this.alarmType = []
        }
      })
      // 历史抓拍
      let stime = ''
      let etime = ''
      if (this.date != null) {
        stime = this.date[0]
        etime = this.date[1]
      }
      const params = {
        equid: this.equDeatil.equid,
        page_index: this.page,
        page_size: this.size,
        stime: stime,
        etime: etime,
        alatype: this.alatype
      }
      aihistoryImg(params).then(res => {
        if (res.status === '1') {
          this.imgList = res.data.data
          if (this.imgList.length > 0) {
            this.notData = true
          } else {
            this.notData = false
          }
          this.total = res.data.recordcount
        } else {
          this.imgList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.getInit()
    },
    handleSearch () {
      this.page = 1
      this.getInit()
    },
    handleReset () {
      this.page = 1
      this.alatype = ''
      this.date = null
      this.getInit()
    },
    goback () {
      this.$emit('goHide')
    },
    openBigImg (item) {
      this.$emit('openImg', item)
    }
  }
}
</script>

<style scoped>
.utF{
  margin: 0 16px;
}
.cam_right  >>> .el-range-editor.el-input__inner {
  padding: 0 10px;
}
.camera_cont{
  width: 100%;
  height: 100%;
  /*padding: 20px 40px 20px 40px;*/
  box-sizing: border-box;
  position: relative;
}
.camera_box{
  width: 100%;
  height: calc(100% - 30px);
  position: relative;
  display: flex;
  /*align-items: center;*/
  justify-content: space-between;
  padding-top: 20px;
  box-sizing: border-box;
}
.cam_left{width: 23%}
.cam_right{width: 75%}
.cam_left,.cam_right{
  height: 100%;
  background: #061A34;
  border: 1px solid rgba(66, 238, 255, 0.4);
  border-radius: 10px;
  padding: 20px 30px;
  box-sizing: border-box;
}
.ud_t {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
.cl_census{
  width: 100%;
  margin-top: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.clcp{
  width: 100%;
  height: 40px;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  margin-bottom: 20px;
}
.cl_alarm{
  margin-top: 30px;
  width: 100%;
}
.ala_item{
  width: 100%;
  height: 115px;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.2);
  padding: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.alaI1{
  border: 1px solid #FF4C4C;
  color: #FF4C4C;
}
.alaI2{
  border: 1px solid #D930EC;
  color: #D930EC;
}
.alaI3{
  border: 1px solid #FFAD42;
  color: #FFAD42;
}
.alaI4{
  border: 1px solid #3476F3;
  color: #3476F3;
}
.claInfo{
  width: calc(100% - 60px);
  padding-left: 20px;
  box-sizing: border-box;
  line-height: 22px;
  font-size: 14px;
}
.claName{
  border-bottom: 1px solid;
  padding-bottom: 6px;
  margin-bottom: 6px;
  box-sizing: border-box;
}
.camR_top{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}
.cam_rList{
  width: 100%;
  min-height: 645px;
}
.cam_table{
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 480px;
}
.listItem{
  width: 20%;
}
.itemBox{
  box-sizing: border-box;
  margin-right: 20px;
  margin-bottom: 30px;
  position: relative;
  border-radius: 4px;
}
.listItem:nth-child(5n+5){
  margin-right: 0px;
}
.itemBox img{
  width: 100%;
  height: 160px;
}
.ImgItem1{
  color: #FF4C4C;
}
.ImgItem2{
  color: #D930EC;
}
.ImgItem3{
  color: #FFAD42;
}
.ImgItem4{
  color: #3476F3;
}
.listInfo{
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 2px 10px;
  box-sizing: border-box;
  line-height: 18px;
  font-size: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.setMore,.setMore >>> .el-select__tags{
  width: 180px;
  height: 35px;
  overflow: hidden;
}
.setMore >>> .el-input__inner{
  background: rgba(136,172,252,0.16);
  border: none;
  color: #A5B7CC;
}
.setMore >>> .el-tag.el-tag--info{
  margin: 5px 0 5px 6px;
  background: transparent;
  color: #A5B7CC;
  border: none;
}
</style>
