<template>
  <div class="box100">
    <div class="frT_top">
      <div class="rig_title"><i class="el-icon-caret-right" style="margin: 0px 10px;"></i><p>耗电量</p></div>
      <div class="alarm_box flexAC two_echS">
        <el-select v-model="twSelectOne" multiple placeholder="线路选择" class="utG setSe dxSet" @change="hdlSelect">
          <el-option
            v-for="item in twSelectOneOption"
            :key="item.ctuid"
            :label="'断路器'+item.ctuname"
            :value="item.ctuid">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="oneDate"
          type="daterange"
          class="utF"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin: 0 10px;">
        </el-date-picker>
        <el-button class="searchbtn" @click="searchHandle">搜索</el-button>
        <el-button class="resetbtn" @click="resetHandle">重置</el-button>
      </div>
      <div class="echartsBox">
        <div v-if="quData.length" style="width: 100%;height: 100%" ref="quantity"></div>
        <div v-if="!quData.length" class="notDataBox">
          <img src="../../assets/img/notdata.png" style="width: 150px;"/>
          <p style="color: #A5B7CC;">暂无数据</p>
        </div>
      </div>
    </div>
    <div class="frT_fot">
      <div class="rig_title">
        <i class="el-icon-caret-right" style="margin: 0px 10px;"></i><p>参数统计</p>
        <div class="flexCB" style="position: absolute;right: 0px;">
          <p class="deP" v-for="item in deploy" :key="item.index"
             @click="TypeChange(item)"
             :class="{'depActive': deploySelect === item.id}">{{ item.name }}</p>
        </div>
      </div>
      <div class="alarm_box flexAC two_echS">
        <el-select v-model="twSelectTwo" multiple placeholder="线路选择" class="utG setSe dxSet" @change="cstjSelect">
          <el-option
            v-for="item in twSelectOneOption"
            :key="item.ctuid"
            :label="'断路器'+item.ctuname"
            :value="item.ctuid">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="twoDate"
          type="daterange"
          class="utF"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin: 0 10px;">
        </el-date-picker>
        <el-button class="searchbtn" @click="csHandle">搜索</el-button>
        <el-button class="resetbtn" @click="csReset">重置</el-button>
      </div>
      <div class="echartsBox">
        <div v-if="tjLength" style="width: 100%;height: 100%" ref="census"></div>
        <div v-if="!tjLength" class="notDataBox">
          <img src="../../assets/img/notdata.png" style="width: 150px;"/>
          <p style="color: #A5B7CC;">暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var echarts = require('echarts')
import { getTodayDate, getRecentDay } from '@/utils/validate'
import { xlDown, Elequantity, paramType, paramTjEch } from '@/http/api/work'
export default {
  name: 'breakerEcharts',
  props: ['equDeatil'],
  data () {
    return {
      twSelectOne: '',
      twSelectOneOption: [],
      twSelectTwo: '',
      oneDate: [],
      twoDate: [],
      deploy: [],
      deploySelect: '',
      deployUnit: '',
      quXAxis: [],
      quData: [],
      tjXAxis: [],
      tjDataList: [],
      tjLength: false
    }
  },
  beforeMount () {
    /* 默认日期 七天 */
    this.getDefaultDateO()
    this.getDefaultDateT()
  },
  mounted () {
    this.getXlList()
    this.$nextTick(() => {
      this.getDlData()
    })
  },
  methods: {
    getDefaultDateO () {
      let startTime = getRecentDay()
      let entTime = getTodayDate()
      this.oneDate = [startTime, entTime]
    },
    getDefaultDateT () {
      let startTime = getRecentDay()
      let entTime = getTodayDate()
      this.twoDate = [startTime, entTime]
    },
    getXlList () {
      xlDown({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.twSelectOneOption = res.data
          if (this.twSelectOneOption.length > 0) {
            let arr = []
            if (this.twSelectOneOption.length < 6) {
              this.twSelectOneOption.forEach((item) => {
                arr.push(item.ctuid)
              })
              this.twSelectTwo = arr
            } else {
              for (let j = 0; j < 6; j++) {
                arr.push(this.twSelectOneOption[j].ctuid)
              }
              this.twSelectTwo = arr
            }
          }
          this.getEtCs()
        } else {
          this.twSelectOneOption = []
        }
      })
    },
    getEtCs () {
      paramType().then(res => {
        if (res.status === '1') {
          this.deploy = res.data
          this.deploySelect = this.deploy[0].id
          this.deployUnit = this.deploy[0].unit
          this.getCsTjData()
        } else {
          this.deploy = []
        }
      })
    },
    hdlSelect (e) {
      if (e.length > 6) {
        this.$message.warning('最多支持6路')
        this.twSelectOne.splice(-1)
      }
    },
    cstjSelect (e) {
      if (e.length > 6) {
        this.$message.warning('最多支持6路')
        this.twSelectTwo.splice(-1)
      }
    },
    searchHandle () {
      this.getDlData()
    },
    /* 电量重置 */
    resetHandle () {
      this.twSelectOne = ''
      this.getDefaultDateO()
      this.$nextTick(() => {
        this.getDlData()
      })
    },
    /* 电量 */
    getDlData () {
      let sTime, eTime = ''
      if (this.oneDate != null) { sTime = this.oneDate[0]; eTime = this.oneDate[1] }
      const param = {
        equid: this.equDeatil.equid,
        ctuid: this.twSelectOne,
        stime: sTime,
        etime: eTime
      }
      this.quXAxis = []
      this.quData = []
      Elequantity(param).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            for (var i in res.data) {
              this.quXAxis.push(res.data[i].time)
              this.quData.push(res.data[i].dl)
            }
            this.$nextTick(()=> {
              this.getDlEcharts()
            })
          }
        } else {}
      })
    },
    getDlEcharts () {
      let max = Math.max(...this.quData)
      if (max > 5) { max = Math.max(...this.quData) } else { max = 5 }
      let echartsRef = echarts.init(this.$refs.quantity)
      let option = {
        grid: {
          top: '16%',
          bottom: 5,
          left: '1%',
          right: '0%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(136, 172, 252, 0.16)',
          borderColor: 'rgba(136, 172, 252, 0.16)',
          textStyle: { color: '#fffff' }
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          offset: 5,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { color: '#A5B7CC',fontSize: 12 },
          data: this.quXAxis
        },
        yAxis: {
          name: '单位：(KW)',
          nameLocation: 'end',
          nameTextStyle: { left: '0', color: '#A5B7CC' },
          type: 'value',
          max: max,
          axisLabel: { color: '#A5B7CC' },
          splitLine: {
            lineStyle: {
              color: '#eee',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          }
        },
        series: {
          name: '耗电量',
          type: 'bar',
          barWidth: '30px',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0, color: 'rgba(255, 98, 66, 1)'
              }, {
                offset: 1, color: 'rgba(255, 98, 66, 0.1)'
              }], false)
            }
          },
          data: this.quData
        }
      }
      echartsRef.setOption(option)
    },
    csHandle () {
      this.getCsTjData()
    },
    /* 参数统计重置 */
    csReset () {
      this.getDefaultDateT()
      this.getXlList()
    },
    TypeChange (item) {
      this.deploySelect = item.id
      this.deployUnit = item.unit
      this.$nextTick(() => {
        this.getCsTjData()
      })
    },
    /* 参数统计 */
    getCsTjData () {
      let sTime, eTime = ''
      if (this.twoDate != null) { sTime = this.twoDate[0]; eTime = this.twoDate[1] }
      const param = {
        equid: this.equDeatil.equid,
        ctuid: this.twSelectTwo,
        types: this.deploySelect,
        stime: sTime,
        etime: eTime
      }
      this.tjXAxis = []
      this.tjDataList = []
      paramTjEch(param).then(res => {
        if (res.status === '1') {
          if (res.data.data.length > 0) {
            this.tjLength = true
            this.tjXAxis = res.data.time
            this.tjDataList = res.data.data
            this.$nextTick(()=> {
              this.getCsTjEcharts()
            })
          } else {
            this.tjLength = false
          }
        }
      })
    },
    getCsTjEcharts () {
      let seriesList = []
      let legendList = []
      this.tjDataList.forEach((item) => {
        seriesList.push({
          name: '断路器' + item.name,
          type: 'line',
          symbol: 'none',
          smooth: true,
          data: item.num
        })
        legendList.push('断路器' + item.name)
      })
      let echartsRef = echarts.init(this.$refs.census)
      let option = {
        color: ['#1FDB8A', '#42EEFF', '#FF4C4C', '#4295FF', '#FFAD42', '#FF5C92'],
        grid: {
          top: '16%',
          bottom: 5,
          left: '1%',
          right: '0%',
          containLabel: true
        },
        legend: {
          right: 0,
          top: -5,
          textStyle: { color: '#A5B7CC' },
          data: legendList
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(136, 172, 252, 0.16)',
          borderColor: 'rgba(136, 172, 252, 0.16)',
          textStyle: { color: '#fffff' }
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          offset: 5,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { color: '#A5B7CC',fontSize: 12 },
          data: this.tjXAxis
        },
        yAxis: {
          name: '单位：('+ this.deployUnit +')',
          nameLocation: 'end',
          nameTextStyle: { left: '0', color: '#A5B7CC' },
          type: 'value',
          axisLabel: { color: '#A5B7CC' },
          splitLine: {
            lineStyle: {
              color: '#eee',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          }
        },
        series: seriesList
      }
      echartsRef.setOption(option)
    }
  }
}
</script>

<style scoped>
.frT_fot{
  margin-top: 20px;
}
.two_echS{
  margin: 15px 0px;
  position: relative;
}
.periodClass{
  width: auto;
  position: absolute;
  right: 0;
}
.periodClass p{
  width: 50px;
  height: 20px;
  cursor: pointer;
  line-height: 20px;
  font-size: 12px;
  background: rgba(136, 172, 252, 0.16);
  text-align: center;
  margin: 0 1px;
  color: #A5B7CC;
}
.periodClass .selected{
  color: #fff;
}
.periodClass p:first-child{
  border-radius: 10px 0px 0px 10px;
}
.periodClass p:last-child{
  border-radius: 0px 10px 10px 0px;
}
.alarm_box >>> .el-range-editor .el-range-input{
  color: #fff;
}
.deP{
  margin: 0px 15px;
  font-size: 14px;
  cursor: pointer;
}
.depActive{
  color: #FFAD42;
}
.echartsBox{
  width: 100%;
  height: 180px;
  position: relative;
  /*background: rgba(136, 172, 252, 0.16);*/
}
.dxSet{
  width: 175px;
  overflow: hidden;
}
.dxSet >>> .el-select__tags>span{
  display: flex;
}
.dxSet >>> .el-tag.el-tag--info{
  background: rgba(136, 172, 252, 0.16);
  border-color: rgba(136, 172, 252, 0.16);
  color: #fff;
}
</style>
