<template>
  <!-- 处理记录 -->
  <div class="box100">
    <div class="rig_title">
      <i class="el-icon-caret-right" style="margin:0 10px;"></i> <p>处理记录</p>
      <span class="back" @click="goBack">返回</span>
    </div>
    <div class="alarm_box flexCB">
      <div>
        <el-input  placeholder="请输入搜索内容" class="contInput" v-model="ectname"></el-input>
        <!-- <el-select v-model="alatime" placeholder="核警时长" class="utG setSe">
          <el-option
            v-for="item in alatimeOption"
            :key="item.id"
            :label="item.value"
            :value="item.id">
          </el-option>
        </el-select> -->
        <el-select v-model="equtype" placeholder="设备类型" class="utN setSe">
          <el-option
            v-for="item in equtypeOption"
            :key="item.equtypeid"
            :label="item.equtypename"
            :value="item.equtypeid">
          </el-option>
        </el-select>
        <!-- <el-select v-model="hejio" placeholder="核警状态" class="utF setSe">
          <el-option
            v-for="item in hejioOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select> -->
        <el-select v-model="dealtype" placeholder="处理结果" class="utF setSe">
          <el-option
            v-for="item in dealtypeOption"
            :key="item.dealtype"
            :label="item.value"
            :value="item.dealtype">
          </el-option>
        </el-select>
        <el-select v-model="alatype" placeholder="告警类型" class="utN setSe">
          <el-option
            v-for="item in alatypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
        <el-button class="resetbtn" @click="handleReset">重置</el-button>
      </div>
      <div><el-button class="exportbtn" @click="handleExport">导出</el-button></div>
    </div>
    <div class="alarm_table hidTable">
      <el-table
        v-loading="loading"
        :data="histList"
        style="width: 100%">
        <template slot="empty">
          <p>{{dataText}}</p>
        </template>
        <el-table-column
          label="序号"
          type="index"
          width="50px">
        </el-table-column>
        <el-table-column
          label="单位名称"
          prop="companyname"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="告警编号"
          prop="ectpid"
          width="200px">
        </el-table-column>
        <el-table-column
          label="告警内容"
          prop="ectpmark"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="设备类型"
          prop="equname">
        </el-table-column>
        <el-table-column
          label="告警类型"
          prop="ectptypename">
        </el-table-column>
        <el-table-column
          label="告警时间"
          prop="ectptime"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="告警地点"
          prop="adderss"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="处理时间"
          prop="dealtime"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="处理人"
          prop="username">
        </el-table-column>
        <el-table-column
          label="处理结果"
          prop="dealtype,dealresults">
          <template slot-scope="scope">
            <div v-if="scope.row.dealtype == 1" class="two">已处理</div>
            <div v-if="scope.row.dealtype == 2" class="three">误报</div>
            <div v-if="scope.row.dealtype == 3" class="one">真实火警</div>
            <div v-if="scope.row.dealtype == 4" class="one">拒不配合</div>
            <div v-if="scope.row.dealtype == 5" class="one">项目转接</div>
            <div v-if="scope.row.dealtype == 6" class="one">技术协助</div>
            <div v-if="scope.row.dealtype != 1 && scope.row.dealtype != 2 && scope.row.dealtype != 3 && scope.row.dealtype != 4 && scope.row.dealtype != 5 && scope.row.dealtype != 6" class="two">已上报</div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" style="color: #42EEFF;" @click="handleDetail(scope.row)">详情</el-button>
            <el-button type="text" size="small" style="color: #42EEFF;" @click="handleReport(scope.row)" v-if="scope.row.dealtype == 4">生成报告</el-button>
            <el-button type="text" size="small" style="color: #42EEFF;" @click="handleAppear(scope.row)" v-if="scope.row.dealtype == 4">上报</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="indexPage">
    </el-pagination>
  </div>
</template>

<script>
import { alarmdeaList, nuclearState, alarmExport, dealResults } from '@/http/api/business'
import { getEquipType } from '@/http/api/equip'
export default {
  name: 'history',
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      ectname: '',
      // alatime: '',
      // alatimeOption: [],
      equtype: '',
      equtypeOption: [],
      // hejio: '',
      // hejioOption: [],
      dealtype: '',
      dealtypeOption: [],
      alatype: '',
      alatypeOption: [
        { label: '全部', value: '0' },
        { label: '火警信息', value: '1' },
        { label: '故障', value: '2' },
        { label: '隐患', value: '3' },
        { label: '其他', value: '4' }
      ],
      histList: [],
      dataText: "", //进去页面先让字样为空
      loading: true
    }
  },
  created () {
    this.init()
    this.getInit()
  },
  methods: {
    /* 返回-处理记录 */
    goBack () {
      this.$emit('historyBack')
    },
    init () {
      const params = {
        ectname: this.ectname,
        equtype: this.equtype,
        // hejio: this.hejio,
        alatype: this.alatype,
        // alatime: this.alatime,
        dealtype: this.dealtype,
        page_index: this.page,
        page_size: this.size
      }
      this.dataText = ""
      alarmdeaList(params).then(res => {
        if (res.status === '1') {
          this.loading = false
          this.histList = res.data.data
          this.total = res.data.recordcount
          if (this.histList.length === 0) {
            this.dataText = "暂无数据"
          }
        } else {
          this.histList = []
          this.total = 0
        }
      })
    },
    getInit () {
      // 设备类型
      getEquipType().then(res => {
        if (res.status === '1') { this.equtypeOption = res.data.data }
        else { this.equtypeOption = [] }
      })

      // 处理结果
      dealResults().then(res => {
        if (res.status === '1') { this.dealtypeOption = res.data }
        else { this.dealtypeOption = [] }
      })

      // 核警时长
      // nuclearLong().then(res => {
      //   if (res.status === '1') { this.alatimeOption = res.data }
      //   else { this.alatimeOption = [] }
      // })

      // 核警状态
      // nuclearState().then(res => {
      //   if (res.status === '1') { this.hejioOption = res.data }
      //   else { this.hejioOption = [] }
      // })
    },
    // 导出
    handleExport () {
      const params = {
        ectname: this.ectname,
        equtype: this.equtype,
        dealtype: this.dealtype,
        alatype: this.alatype
      }
      alarmExport(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          window.open(res.data.filepath)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 详情
    handleDetail (row) {
      this.$emit('hisHandDetail', row)
    },
    // 生成报告
    handleReport (row) {

    },
    // 上报
    handleAppear (row) {

    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.ectname = ''
      this.equtype = ''
      this.dealtype = ''
      this.alatype = ''
      this.init()
    },
  }
}
</script>

<style scoped>
.alarm_box {
  margin-top: 20px;
}
/* .hidTable {
  height: 600px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.hidTable::-webkit-scrollbar {
  width: 0px;
} */
.utF {
  margin: 0 16px;
}
.searchbtn {
  margin-left: 16px;
}
.one {
  color: #FF944C;
  width: 76px;
  height: 29px;
  background: rgba(6, 26, 52, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 4px;
  padding: 3px 10px;
  box-sizing: border-box;
}
.three {
  color: #FF944C;
  width: 48px;
  height: 29px;
  background: rgba(6, 26, 52, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 4px;
  padding: 3px 10px;
  box-sizing: border-box;
}
.two {
  color: #29CB85;
  width:58px;
  height: 29px;
  background: rgba(6, 26, 52, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 4px;
  padding: 3px 8px;
  box-sizing: border-box;
}
.el-loading-mask {
  background: none;
  top: 100px;
}
</style>
