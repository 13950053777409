<!-- 断路器 -->
<template>
  <div class="queDetail_contains">
    <div class="rig_title">
      <i class="el-icon-caret-right" style="margin:0 10px;"></i>
      <p>智能断路器</p>
      <span class="back" @click="goBack">返回</span>
    </div>
    <div class="detail_cont">
      <div class="dc_left flexC">
        <img src="../../assets/img/breaker.png"/>
        <div class="dcl_rig">
          <div class="flexCB">
            <p class="equStart sta1">{{ brbValue0 }}</p>
            <p class="equsTitle">{{ brbValue1 }}</p>
          </div>
<!--          <p class="xhStrength">信号强度<br><span style="color: #fff;">3511dBm</span></p>-->
        </div>
      </div>
      <!--  基本信息  -->
      <div class="dc_middle">
        <div class="flexAC"><div class="udi_k"></div><p class="ud_t">设备基本信息</p></div>
        <p class="ud_c">单位名称：{{ equDeatil.companyname }}</p>
        <p class="ud_c">安装位置：{{ equDeatil.adderss }}</p>
        <div class="flexCB">
          <p class="ud_c">设备编号：{{ equDeatil.equimei }}</p>
          <p class="ud_c" style="width: 35%">设备名称：{{ equDeatil.equname }}</p>
        </div>
        <div class="flexCB">
          <p class="ud_c">安全管理人：{{ equDeatil.username }}</p>
          <p class="ud_c" style="width: 35%">电话：{{ equDeatil.userphone }}</p>
        </div>
      </div>
      <!-- 设备告警统计  -->
      <div class="dc_right">
        <div class="flexAC"><div class="udi_k"></div><p class="ud_t">设备告警统计</p></div>
        <div class="flexCB" style="margin-top: 20px;">
          <div v-for="(item, index) in alarmType" :key="index" :class="'typeA'+index"
               @click="goAlarmDialog(item, index)">
            <p class="alarm_num">{{ item.value }}</p>
            <p class="alarm_info">{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>


    <!--  断路器数据  -->
    <div class="detail_fot">
      <div class="del_fL">
        <p class="typeName" v-for="(item, index) in dataClass" :key="index"
           :class="{'dataActive': dataState === index}" @click="changeAla(index)">{{ item }}</p>
      </div>
      <div class="del_fR">
        <!-- 断路器 -->
        <div class="delFR_One" v-if="dataState === 0">
          <div class="fr_item" v-for="item in breakerList" :key="item.index">
            <div class="frItemL">
              <div class="il_name"><p>断路器{{ item.ctuname }}</p><p>（{{ item.ctuvery }}）</p></div>
              <div class="il_img" v-if="item.ctuswit === true"><img src="../../assets/img/breaker_s1.png"/></div>
              <div class="il_img" v-if="item.ctuswit === false"><img src="../../assets/img/breaker_s2.png"/></div>
              <div class="il_equ" @click="showGl(item)"><p>关联设备</p><p>{{ item.ctuasso.length }}</p></div>
              <div class="il_switch off" v-if="item.ctuswit === true">
                <p>开关</p>
                <el-switch class="off_bes" v-model="item.ctuswit" @change="offChange($event, item)" active-color="#13ce66" inactive-color="rgba(0, 0, 0, 0.3)" ></el-switch>
              </div>
              <div class="il_switch open" v-if="item.ctuswit === false">
                <p>开关</p>
                <el-switch class="off_bes" v-model="item.ctuswit" @change="offChange($event, item)" active-color="#13ce66" inactive-color="rgba(0, 0, 0, 0.3)" ></el-switch>
              </div>
            </div>
            <div class="frItemR">
              <p class="ir_top">{{ item.ctumark }}</p>
              <div class="ir_fot">
                <div class="ir_li" v-for="param in item.ctubass" :key="param.index">
                  <p class="ir_W">{{ param.name }}(阈值{{ param.scope }}{{ param.unit }})</p><p>{{ param.value }}{{ param.unit }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- 数据分析 -->
        <div class="delFR_Two" v-if="dataState === 1">
          <BreakerEcharts :equDeatil="equDeatil"></BreakerEcharts>
        </div>


        <!-- 控制日志 -->
        <div class="delFR_Three " v-if="dataState === 2">
          <div class="alarm_box flexAC">
            <el-input  placeholder="请输入搜索内容" class="contInput" v-model="thKeyWord"></el-input>
            <el-select v-model="thKeyUser" placeholder="操作人员" class="utG setSe">
              <el-option
                v-for="item in thKeyUserOption"
                :key="item.oluser"
                :label="item.username"
                :value="item.oluser">
              </el-option>
            </el-select>
            <el-date-picker
              v-model="thDate"
              type="daterange"
              class="utF"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="margin: 0 10px;">
            </el-date-picker>
            <el-button class="searchbtn" @click="rzHandle">搜索</el-button>
            <el-button class="resetbtn" @click="rzReset">重置</el-button>
          </div>
          <div class="alarm_table hidTable">
            <el-table :data="dailyList" style="min-height: 380px;">
              <el-table-column
                label="序号"
                type="index">
              </el-table-column>
              <el-table-column
                label="断路器"
                prop="ctuname">
              </el-table-column>
              <el-table-column
                label="线路说明"
                prop="ctumark">
              </el-table-column>
              <el-table-column
                label="操作内容"
                prop="olmark">
              </el-table-column>
              <el-table-column
                label="级数"
                prop="ctuvery">
              </el-table-column>
              <el-table-column
                label="操作人"
                prop="username">
              </el-table-column>
              <el-table-column
                label="操作时间"
                prop="oltime">
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
            @current-change="pageChange"
            :current-page="dailyPage"
            :page-sizes="[10]"
            :page-size="dailySize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dailyTotal"
            class="indexPage"
            style="margin-top: 10px;">
          </el-pagination>
        </div>
      </div>
    </div>

    <!--  关联设备弹框  -->
    <el-dialog  :visible.sync="relationShow" center class="hdBg" width="910px" :close-on-click-modal="false" :before-close="cancelGl">
      <div class="rela_cont">
        <div class="rig_title"><i class="el-icon-caret-right" style="margin: 0px 10px;"></i><p>基本信息</p></div>
        <div class="flexF fpInfo">
          <p class="fp">断路器序号：断路器{{ glInfo.ctuname }}</p>
          <p class="fp">断路器型号：{{ glInfo.ctucode }}</p>
          <p class="fp">断路器极数：{{ glInfo.ctuvery }}</p>
          <p class="fp">线路说明：{{ glInfo.ctumark }}</p>
        </div>
        <div class="rig_title"><i class="el-icon-caret-right" style="margin: 0px 10px;"></i><p>关联设备</p></div>
        <div class="flexF fpReInfo">
          <p class="fpRe" v-for="glEqu in glInfo.ctuasso" :key="glEqu.index">{{ glEqu.name }}</p>
        </div>
        <div class="handBtnBox flexC" v-if="isGlShow">
          <div class="afConfirm" @click="cancelGl" style="margin-right: 20px;">取消</div>
          <div class="afCancel" @click="hfHandle">确定</div>
        </div>
      </div>
    </el-dialog>

    <!-- 告警列表 -->
    <el-dialog title="设备告警" :visible.sync="alarmListShow" center class="alarmDialog hdBg hdTb" width="1480px" :close-on-click-modal="false">
      <AlarmList :equDeatil="equDeatil" :type="type"></AlarmList>
    </el-dialog>

    <!-- 故障列表 -->
    <el-dialog title="设备故障" :visible.sync="faultListShow" center class="alarmDialog hdBg hdTb" width="1480px" :close-on-click-modal="false">
      <FaultList :equDeatil="equDeatil" :type="type"></FaultList>
    </el-dialog>

    <!-- 误报列表 -->
    <el-dialog title="设备误报" :visible.sync="distortListShow" center class="alarmDialog hdBg hdTb" width="1480px" :close-on-click-modal="false">
      <DistortList :equDeatil="equDeatil" :type="type"></DistortList>
    </el-dialog>
  </div>
</template>

<script>
import AlarmList from '@/components/work/alarmList.vue'
import FaultList from '@/components/work/faultList.vue'
import DistortList from '@/components/work/distortList.vue'
import BreakerEcharts from '../../components/work/breakerEcharts'
import { alarmStatis, breakerOpen, breakerJournal, operator } from '@/http/api/work'
import { getCircuitEqu } from '@/http/api/equip'
export default {
  name: 'breaker',
  props: ['equDeatil'],
  components: {
    BreakerEcharts,
    AlarmList,
    FaultList,
    DistortList
  },
  data () {
    return {
      alarmType: [{ name: '设备告警', value: 2568 },{ name: '设备故障', value: 2568 },{ name: '设备误报', value: 2568 }],
      dataState: 0,
      dataClass: ['断路器', '数据分析', '控制日志'],
      bSwitch: false,
      relationShow: false,
      thKeyWord: '',
      thKeyUser: '',
      thKeyUserOption: [],
      thDate: null,
      dailyPage: 1,
      dailySize: 10,
      dailyTotal: 0,
      dailyList: [],
      alarmListShow: false,
      faultListShow: false,
      distortListShow: false,
      type: '',
      breakerBass: [],
      brbValue0: '',
      brbValue1: '',
      breakerList: [],
      glInfo: '',
      isGlShow: false
    }
  },
  created () {
    this.init()
    this.getBreaker()
  },
  methods: {
    init () {
      /* 告警统计总数 */
      alarmStatis({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.alarmType = res.data
        } else {
          this.alarmType = []
        }
      })
      /* 人员选择下来 */
      operator({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.thKeyUserOption = res.data
        } else {
          this.thKeyUserOption = []
        }
      })
    },
    /* 断路器列表 */
    getBreaker (){
      getCircuitEqu({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.breakerList = res.data.config
          this.breakerBass = res.data.bass
          if (this.breakerBass.length > 0) {
            this.brbValue0 = this.breakerBass[0].value
            this.brbValue1 = this.breakerBass[1].value
          }
        } else {
          this.breakerList = []
        }
      })
    },
    /* 告警统计弹框 */
    goAlarmDialog (item, index) {
      this.type = item.type
      if (index === 0) {
        this.alarmListShow = true
      } else if (index === 1) {
        this.faultListShow = true
      } else {
        this.distortListShow = true
      }
    },
    goBack () {
      this.$emit('goback')
    },
    /* 断路器 数据 日志 切换 */
    changeAla (val) {
      this.dataState = val
      if (this.dataState === 2) {
        this.getJournal()
      }
    },
    /* 合闸 */
    offChange (val, item) {
      item.ctuswit = !val
      this.relationShow = true
      this.isGlShow = true
      this.glInfo = item
    },
    /* 展示关联  */
    showGl (item) {
      this.relationShow = true
      this.isGlShow = false
      this.glInfo = item
    },
    /* 关闭关联 */
    cancelGl () {
      this.relationShow = false
    },
    /* 合闸 */
    hfHandle () {
      let log = ''
      if (this.glInfo.ctuswit === true) { log = '断电' }
      else { log = '通电' }
      this.$confirm('确定是否对"断路器'+ this.glInfo.ctuname +'"进行'+ log +'操作', '断路器操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'Misinform',
        center: true
      }).then(() => {
        const param = {
          equid: this.equDeatil.equid,
          equimei: this.equDeatil.equimei,
          ctuname: this.glInfo.ctuname,
          ctuswit: this.glInfo.ctuswit,
          ctuid: this.glInfo.ctuid
        }
        breakerOpen(param).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.relationShow = false
            this.getBreaker()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    /* 日志翻页 */
    pageChange (page) {
      this.dailyPage = page
      this.getJournal()
    },
    /* 日志列表 */
    getJournal () {
      let sTime, eTime = ''
      if (this.thDate != null) { sTime = this.thDate[0]; eTime = this.thDate[1] }
      const param = {
        equid: this.equDeatil.equid,
        name: this.thKeyWord,
        userid: this.thKeyUser,
        stime: sTime,
        etime: eTime,
        page_index: this.dailyPage,
        page_size: this.dailySize
      }
      breakerJournal(param).then(res => {
        if (res.status === '1') {
          this.dailyList = res.data.data
          this.dailyTotal = res.data.recordcount
        } else {
          this.dailyList = []
          this.dailyTotal = 0
        }
      })
    },
    /* 日志搜索 */
    rzHandle () {
      this.dailyPage = 1
      this.getJournal()
    },
    /* 重置 */
    rzReset () {
      this.dailyPage = 1
      this.thDate = null
      this.thKeyWord = ''
      this.thKeyUser = ''
      this.getJournal()
    }
  }
}
</script>

<style scoped>
.detail_cont{
  width: 100%;
  height: 240px;
  position: relative;
  margin: 30px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dc_left{
  width: 22.5%;
  height: 100%;
}
.dc_middle{
  width: 33%;
  height: 100%;
  margin: 0 10px;
}
.dc_right{
  width: 44.5%;
  height: 100%;
}
.dc_left, .dc_middle, .dc_right{
  border: 1px solid rgba(66, 238, 255, 0.4);
  background: rgba(6, 26, 52, 0.4);
  border-radius: 8px;
  padding: 25px 30px;
  box-sizing: border-box;
  position: relative;
  font-size: 14px;
}
.dcl_rig{
  width: 130px;
  height: 185px;
  margin-left: 8px;
  background: rgba(136, 172, 252, 0.16);
  padding: 15px 10px;
  box-sizing: border-box;
}
.equStart{
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  text-align: center;
}
.sta1{background: #E2335C;}
.sta2{background: #1FDB8A;}
.equsTitle{
  width: 52px;
  height: 50px;
  text-align: center;
  background: rgba(136, 172, 252, 0.16);
  line-height: 16px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
}
.xhStrength{
  width: 100%;
  height: 50px;
  line-height: 20px;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 4px;
  text-align: center;
  margin-top: 8px;
  color: #A5B7CC;
  padding-top: 5px;
  box-sizing: border-box;
}
.ud_t {
  font-size: 16px;
  /*font-family: 'PingFang SC';*/
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
.ud_c{
  margin-top: 15px;
}
.typeA0,.typeA1,.typeA2 {
  width: 220px;
  height: 140px;
  background: rgba(241, 55, 55, 0.1);
  border: 1px solid #F13737;
  opacity: 1;
  border-radius: 10px;
  text-align: center;
  color: #F13737;
  padding-top: 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.typeA1 {
  background: rgba(255, 148, 76, 0.1);
  border: 1px solid #FF944C;
  color: #FF944C;
}
.typeA2 {
  background: rgba(66, 238, 255, 0.1);
  border: 1px solid #42EEFF;
  color: #42EEFF;
}
.alarm_num {
  font-size: 40px;
  line-height: 30px;
  margin-bottom: 8px;
}
.alarm_info{
  font-size: 14px;
  color: #fff;
}
.detail_fot{
  width: 100%;
  height: calc(100% - 320px);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.del_fL{
  width: 300px;
  height: auto;
  background: rgba(136, 172, 252, 0.16);
  padding: 20px 12px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 4px;
}
.typeName{
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  margin-bottom: 12px;
  cursor: pointer;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 4px;
}
.typeName:last-child{
  margin-bottom: 0px;
}
.dataActive{
  color: #FFAD42;
}
.del_fR{
  width: calc(100% - 320px);
  margin-left: 20px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  /*border: 1px solid rgba(66, 238, 255, 0.4);*/
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;/* //火狐兼容 */
}
.del_fR::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.delFR_One, .delFR_Two, .delFR_Three{
  width: 100%;
  height: auto;
  min-height: 98%;
  position: relative;
  background: rgba(6, 26, 52, 0.4);
  border: 1px solid rgba(66, 238, 255, 0.4);
  border-radius: 8px;
  padding: 30px;
  box-sizing: border-box;
}
.delFR_One{
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  box-sizing: border-box;
  align-content: flex-start;
}
.fr_item{
  width: 340px;
  height: auto;
  max-height: 438px;
  padding: 26px;
  margin: 15px;
  border-radius: 8px;
  box-sizing: border-box;
  background: rgba(6, 26, 52, 0.4);
  border: 4px solid rgba(66, 149, 255, 0.2);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.frItemL{
  width: 68px;
  height: 100%;
  position: relative;
  margin-right: 12px;
}
.frItemR{
  width: 200px;
  height: 100%;
  position: relative;
}
.il_name,.il_equ{
  width: 100%;
  height: 50px;
  line-height: 20px;
  padding-top: 5px;
  box-sizing: border-box;
  text-align: center;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 4px;
}
.il_img{
  width: 100%;
  height: 176px;
  margin-top: 8px;
}
.il_equ{
  margin-top: 12px;
  color: #FFAD42;
  cursor: pointer;
}
.il_switch{
  width: 100%;
  height: 70px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  margin-top: 12px;
  box-sizing: border-box;
}
.off{
  background: #1FDB8A;
  padding: 10px 0px;
  box-sizing: border-box;
}
.off_bes{
  width: 42px;
  height: 20px;
  margin-top: 5px;
}
.off_bes>>>.el-switch__core{border: none;}
.off_bes>>>.el-switch__core:after{width: 18px;height: 18px}
.open{
  background: #E2335C;
  padding: 10px 0px;
  box-sizing: border-box;
}
.ir_top{
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0px 10px;
  box-sizing: border-box;
  background: rgba(136, 172, 252, 0.16);
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ir_fot{
  width: 100%;
  height: 320px;
  margin-top: 8px;
  padding: 5px 10px;
  box-sizing: border-box;
  overflow: hidden;
  background: rgba(136, 172, 252, 0.16);
  overflow-y: scroll;  scrollbar-width: none;/* //火狐兼容 */
}
.ir_fot::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.ir_li{
  width: 100%;
  height: 40px;
  line-height: 20px;
  margin: 5px 0px;
}
.ir_W{
  color: #A5B7CC;
}
.fpInfo{
  color: #fff;
  padding: 10px 0px;
}
.fp{
  width: 33%;
  margin: 8px 0px;
}
.fpReInfo{
  color: #fff;
  min-height: 250px;
}
.fpRe{
  width: auto;
  height: 35px;
  background: rgba(136, 172, 252, 0.16);
  padding: 8px 10px;
  box-sizing: border-box;
  text-align: center;
  margin-right: 20px;
  margin-top: 20px;
}
.alarm_box >>> .el-range-editor .el-range-input{
  color: #fff;
}
</style>
