<template>
  <!-- 可视对讲 -->
  <div class="ezu_cont">
    <div class="rig_title">
      <i class="el-icon-caret-right" style="margin:0 10px;"></i>
      <p>{{equDeatil.equname}}</p>
      <span class="back" @click="goBack" >返回</span>
    </div>
    <div class="ezu_container">
      <div class="ezu_video">
        <div id="video-container"></div>
      </div>
      <div class="ezu_info">
        <div class="flexSC">
          <div class="ud_left">
            <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
              <div class="udi_k"></div>
              <p class="ud_t">设备基本信息</p>
            </div>
            <p class="ud_c">单位名称：{{ equDeatil.companyname }}</p>
            <p class="ud_c">设备名称：{{ equDeatil.equname }}</p>
            <p class="ud_c">设备编号：{{ equDeatil.equimei }}</p>
            <p class="ud_c">安装位置：{{ equDeatil.adderss }}</p>
            <p class="ud_c">安全管理人：{{ equDeatil.username }}</p>
            <p class="ud_c">电话：{{ equDeatil.userphone }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EZUIKit from 'ezuikit-js'
import Recorderx from 'recorderx'
import { equCurrent } from '@/http/api/equip'
import $ from 'jquery'
const rc = new Recorderx()

export default {
  props: ['equDeatil'],
  data () {
    return {

    }
  },
  mounted () {
    this.getMicrophone()
    this.getVideo()
    window.URL = window.URL || window.webkitURL
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
  },
  created () {
    // console.log(this.equDeatil)
  },
  methods: {
    // 获取麦克风权限
    getMicrophone () {
      rc.start().then(() => {
        console.log('获取麦克风成功')
      }).catch(error => {
        console.log('失败原因' + error)
      })
    },
    // 可视对讲
    getVideo () {
      equCurrent({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.player =  new EZUIKit.EZUIKitPlayer({
            autoplay: true,
            id: "video-container",
            accessToken: res.data.video_token,
            url: res.data.equip_port,
            template: 'voice', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
            header: ['capturePicture', 'save', 'zoom'], // 如果templete参数不为simple,该字段将被覆盖
            footer: ['talk', 'hd', 'fullScreen'], // 如果template参数不为simple,该字段将被覆盖
            // plugin: ['talk'],
            audio: 1,
            // width: 840,
            height: 600
          })
        }
      })
    },
    goBack () {
      $('#video-container').empty()
      this.$emit('goback')
    }
  }
}
</script>

<style scoped>
.ezu_cont {
  width: 100%;
  height: 100%;
  padding: 20px 40px 0 40px;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  overflow: auto;
}
.ezu_container {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  padding-top: 30px;
  box-sizing: border-box;
}
.ezu_video {
  width: 80%;
}
.ezu_info {
  width: 20%;
  margin-left: 40px;
}
.udi_k {
  width: 4px;
  height: 18px;
  background: #196DF7;
  opacity: 1;
}
.ud_t {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 700;
  line-height: 25px;
  color: #FFFFFF;
  opacity: 1;
  margin-left: 10px;
}
.ud_c {
  font-size: 16px;
  font-family: 'PingFang SC';
  line-height: 23px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 10px;
}
</style>
