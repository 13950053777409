<template>
  <div class="host_cont">
    <div class="cont_box">
      <div class="box100">
        <!-- 防火门 卷帘门 -->
        <img src="../../assets/img/project/door.png" style="width:100%;" v-if="detailInfo.equtype == 11 || detailInfo.equtype == 12"/>
        <!-- 电气火灾 -->
        <div v-if="detailInfo.equtype == 7" class="item1_box">
          <img src="../../assets/img/project/dqhz.png" style="width:100%;"  />
          <div v-if="detailInfo.equcf_basis != ''">
            <div class="dis_num1">{{ detailInfo.equcf_basis[2].value }}{{ detailInfo.equcf_basis[2].unit }}</div>
            <div class="dis_num2">{{ detailInfo.equcf_basis[3].value }}{{ detailInfo.equcf_basis[3].unit }}</div>
            <div class="dis_num3">{{ detailInfo.equcf_basis[4].value }}{{ detailInfo.equcf_basis[4].unit }}</div>
            <div class="dis_num4">{{ detailInfo.equcf_basis[5].value }}{{ detailInfo.equcf_basis[5].unit }}</div>
            <div class="dis_num5">{{ detailInfo.equcf_basis[1].value }}{{ detailInfo.equcf_basis[1].unit }}</div>
          </div>
          <div v-if="detailInfo.equcf_basis[2] == ''">
            <div class="dis_num1">0mA</div>
            <div class="dis_num2">0°C</div>
            <div class="dis_num3">0°C</div>
            <div class="dis_num4">0°C</div>
            <div class="dis_num5">0°C</div>
          </div>
        </div>
        <!-- 机器人 -->
        <img src="../../assets/img/project/jqr.png" style="width:100%;" v-if="detailInfo.equtype == 18"/>
        <!-- 可燃气 -->
        <img src="../../assets/img/project/NBkrq.png" style="width:100%;" v-if="detailInfo.equtype == 5"/>
        <!-- 水位仪 液位 -->
        <div v-if="detailInfo.equtype == 14 || detailInfo.equtype == 9" class="item2_box">
          <img src="../../assets/img/project/water.png" style="width:100%;"/>
          <div class="level_num">
            <p class="wun" v-if="detailInfo.equcf_basis[2] != ''">{{ detailInfo.equcf_basis[2].value }}{{ detailInfo.equcf_basis[2].unit }}</p>
            <p class="wun" v-if="detailInfo.equcf_basis[2] == ''">0M</p>
          </div>
          <div class="level_echart">
            <div class="box100">
              <div style="width:100%;height:100%;" ref="levelchart"></div>
              <p class="high" v-if="showHigh" :style="{ top: highTop + '%' }">— 高水位</p>
              <p class="low" v-if="showLow" :style="{ top: lowTop + '%' }">— 低水位</p>
            </div>
          </div>
        </div>
        <!-- 温感 -->
        <img src="../../assets/img/project/wg.png" style="width:100%;" v-if="detailInfo.equtype == 13"/>
        <!-- 无线手报 -->
        <img src="../../assets/img/project/wxsb.png" style="width:100%;" v-if="detailInfo.equtype == 16"/>
        <!-- 无线声光 -->
        <img src="../../assets/img/project/wxsg.png" style="width:100%;" v-if="detailInfo.equtype == 17"/>
        <!-- 消防主机联网 -->
        <img src="../../assets/img/project/xfzjlw.png" style="width:100%;" v-if="detailInfo.equtype == 6"/>
        <!-- 消火栓 -->
        <div v-if="detailInfo.equtype == 15" class="item3_box">
          <img src="../../assets/img/project/xhs.png" style="width:100%;"/>
          <div class="pre_num" style="top: 20%;">
            <p class="wun" v-if="detailInfo.equcf_basis[2] != ''">{{ detailInfo.equcf_basis[2].value }}{{ detailInfo.equcf_basis[2].unit }}</p>
            <p class="wun" v-if="detailInfo.equcf_basis[2] == ''">0Mpa</p>
          </div>
        </div>
        <!-- 巡检标签 -->
        <img src="../../assets/img/project/xjbq.png" style="width:100%;" v-if="detailInfo.equtype == 19"/>
        <!-- 数据采集传输终端 -->
        <img src="../../assets/img/project/sjcjzd.png" style="width:100%;" v-if="detailInfo.equtype == 22"/>
        <!-- NB烟感 -->
        <img src="../../assets/img/project/yg.png" style="width:100%;" v-if="detailInfo.equtype == 4"/>
        <!-- NB一键报警 -->
        <img src="../../assets/img/project/yjbj.png" style="width:100%;" v-if="detailInfo.equtype == 10"/>
        <!-- 压力计 -->
        <div v-if="detailInfo.equtype == 8" class="item3_box">
          <img src="../../assets/img/project/ylj.png" style="width:100%;"/>
          <div class="pre_num">
            <p class="wun" v-if="detailInfo.equcf_basis[2] != ''">{{ detailInfo.equcf_basis[2].value }}{{ detailInfo.equcf_basis[2].unit }}</p>
            <p class="wun" v-if="detailInfo.equcf_basis[2] == ''">0Mpa</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEquipList } from '@/http/api/equip'
var echarts = require('echarts')

export default {
  props: ['equDeatil', 'refsh'],
  data () {
    return {
      detailInfo: '',
      showHigh: false,
      showLow: false,
      highTop: 0,
      lowTop: 0
    }
  },
  watch: {
    refsh (val) {
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  created () {
    // this.init()
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    init () {
      getEquipList({ equid: this.equDeatil.equid }).then(res => {
        if (res.status === '1') {
          this.detailInfo = res.data.data[0]
          if (this.detailInfo.equcf_dynamic[0].value != 0 || this.detailInfo.equcf_dynamic[0].value != null || this.detailInfo.equcf_dynamic[0].value != undefined || this.detailInfo.equcf_dynamic[0].value != '') {
            this.showHigh = true
            let hbf = 1 - (this.detailInfo.equcf_dynamic[0].value / this.detailInfo.equhigh)
            this.highTop = hbf.toFixed(2) * 100
          } else {
            this.showHigh = false
          }
          if (this.detailInfo.equcf_dynamic[1].value != 0 || this.detailInfo.equcf_dynamic[1].value != null || this.detailInfo.equcf_dynamic[1].value != undefined || this.detailInfo.equcf_dynamic[1].value != '') {
            this.showLow = true
            let lbf = 1 - (this.detailInfo.equcf_dynamic[1].value / this.detailInfo.equhigh)
            this.lowTop = lbf.toFixed(2) * 100
          } else {
            this.showLow = false
          }
          this.timer = setTimeout(() => {
            this.waterLevel()
          }, 1000)
        } else {
          this.detailInfo = ''
        }
      })
    },
    //* 水位状态 *//
    waterLevel (){
      if(this.detailInfo.equtype == 14 || this.detailInfo.equtype == 9){
        if (this.detailInfo.equcf_basis.length !== 0) {
          var value = parseFloat(this.detailInfo.equcf_basis[2].value)
          if (value != 0) {
            var max = 10
            var wlChart = echarts.init(this.$refs.levelchart)
            var option = {
              backgroundColor:'',
              grid: {
                width: '100%',
                height:'100%',
                left: 0,
                right: 0,
                bottom: 0,
                top:0,
                containLabel: true
              },
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,
                  data: ['data1', 'data2', 'data3', 'data4', 'data5'],
                  axisTick: {show: false}, // 刻度
                  axisLine: {show: false}, // 轴线
                  axisLabel: {show: false}
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  axisTick: {show: false}, // 刻度
                  axisLine: {show: false}, // 轴线
                  splitLine: {show: false},
                  axisLabel: {show: false}
                }
              ],
              series: [
                {
                name: '水波纹1',
                type: 'line',
                symbol: "none",
                lineStyle: {width: 0,color: 'rgba(0, 0, 0, 0)'}, //隐藏线
                smooth:true,
                areaStyle: {
                  normal: {
                  color: '#00BAE0' //改变区域颜色
                  }
                },
                data: [value-0.1, value+0.1, value, value-0.1, value+0.1],
                },
                {
                  name: '水波纹2',
                  type: 'line',
                  symbol: "none",
                  lineStyle: {width: 0,color: 'rgba(0, 0, 0, 0)'}, //隐藏线
                  smooth:true,
                  areaStyle: {
                    normal: {
                    color: '#00BAE0' //改变区域颜色
                    }
                  },
                  data: [value+0.1, value-0.1, value, value+0.1, value-0.1]
                },
                {
                  name: '总值',
                  type: 'line',
                  symbol: "none",
                  lineStyle: {width: 0,color: 'rgba(0, 0, 0, 0)'}, //隐藏线
                  data: [max, max, max, max, max]
                }
              ]
            }
            wlChart.setOption(option)
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.host_cont {
  width: 100%;
  height: 100%;
  position: relative;
}
.cont_box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont_box img {
  width: 100%;
}
.item1_box, .item2_box, .item3_box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 可燃气 */
.dis_num1 {
  width: 75px;
  height: 31px;
  line-height: 32px;
  /* border: 1px solid #ddd; */
  background: rgba(221, 221, 221, 0);
  position: absolute;
  left: 35%;
  top: 22%;
  text-align: center;
  font-size: 16px;
  color: #FFAD42;
}
.dis_num2 {
  width: 75px;
  height: 31px;
  line-height: 32px;
  /* border: 1px solid #ddd; */
  background: rgba(221, 221, 221, 0);
  position: absolute;
  right: 6%;
  top: 23%;
  text-align: center;
  font-size: 16px;
  color: #FFAD42;
}
.dis_num3 {
  width: 75px;
  height: 31px;
  line-height: 32px;
  /* border: 1px solid #ddd; */
  background: rgba(221, 221, 221, 0);
  position: absolute;
  left: 35%;
  top: 36%;
  text-align: center;
  font-size: 16px;
  color: #FFAD42;
}
.dis_num4 {
  width: 75px;
  height: 31px;
  line-height: 32px;
  /* border: 1px solid #ddd; */
  background: rgba(221, 221, 221, 0);
  position: absolute;
  right: 6%;
  top: 36%;
  text-align: center;
  font-size: 16px;
  color: #FFAD42;
}
.dis_num5 {
  width: 75px;
  height: 31px;
  line-height: 32px;
  /* border: 1px solid #ddd; */
  background: rgba(221, 221, 221, 0);
  position: absolute;
  right: 11%;
  bottom: 22%;
  text-align: center;
  font-size: 16px;
  color: #FFAD42;
}
/* 液位 */
.level_num{
  width: 115px;
  height: 65px;
  position:absolute;
  top: 22%;
  left: 11%;
  padding: 6px 10px;
  box-sizing: border-box;
  /* border: 1px solid #ddd; */
}
.wun{
  width: 114px;
  height: 63px;
  position:absolute;
  top: 0%;
  left: 0%;
  color: #FFAD42;
  font-size: 18px;
  line-height: 63px;
  text-align: center;
}
.level_echart{
  width: 111px;
  height: 175px;
  position: absolute;
  top: 26%;
  right: 16.5%;
  /* border: 1px solid #ddd; */
}
.high{
  position: absolute;
  right: -55%;
  top: 10%;
  color: #d03737;
  font-size: 12px;
}
.low{
  position: absolute;
  right: -55%;
  top: 30%;
  color: #d03737;
  font-size: 12px;
}
/* 压力 */
.pre_num{
  width: 115px;
  height: 65px;
  position:absolute;
  top: 23%;
  left: 27%;
  padding: 6px 10px;
  box-sizing: border-box;
  /* border: 1px solid #ddd; */
}
/* 媒体查询 */
@media screen and (max-width: 1680px){
  .dis_num1 {
    width: 65px;
    left: 35%;
    font-size: 14px;
  }
  .dis_num2 {
    width: 65px;
    top: 22%;
    font-size: 14px;
  }
  .dis_num3 {
    width: 65px;
    top: 35%;
    font-size: 14px;
  }
  .dis_num4 {
    width: 65px;
    top: 35%;
    font-size: 14px;
  }
  .dis_num5 {
    width: 60px;
    bottom: 21%;
    font-size: 14px;
  }
  .wun {
    width: 100px;
    height: 56px;
    font-size: 18px;
    line-height: 56px;
  }
  .level_echart {
    width: 106px;
    height: 157px;
    top: 25%;
    right: 15.5%;
  }
}
@media screen and (max-width: 1600px){
  .dis_num1 {
    width: 65px;
    left: 35%;
    font-size: 14px;
  }
  .dis_num2 {
    width: 65px;
    top: 22%;
    font-size: 14px;
  }
  .dis_num3 {
    width: 65px;
    top: 35%;
    font-size: 14px;
  }
  .dis_num4 {
    width: 65px;
    top: 35%;
    font-size: 14px;
  }
  .dis_num5 {
    width: 60px;
    bottom: 21%;
    font-size: 14px;
  }
  .level_echart {
    width: 100px;
    height: 150px;
    top: 25%;
    right: 15.5%;
  }
}
@media screen and (max-width: 1400px){
  .dis_num1 {
    width: 55px;
    left: 35%;
    top: 21%;
    font-size: 12px;
  }
  .dis_num2 {
    width: 55px;
    top: 21%;
    font-size: 12px;
  }
  .dis_num3 {
    width: 55px;
    top: 34%;
    font-size: 12px;
  }
  .dis_num4 {
    width: 55px;
    top: 34%;
    font-size: 12px;
  }
  .dis_num5 {
    width: 55px;
    bottom: 20%;
    font-size: 12px;
  }
  .wun {
    width: 82px;
    height: 45px;
    font-size: 16px;
    line-height: 45px;
  }
  .level_echart {
    width: 87px;
    height: 130px;
    top: 25%;
    right: 15.5%;
  }
}
@media screen and (max-width: 1366px){
  .dis_num1 {
    width: 55px;
    left: 35%;
    top: 21%;
    font-size: 12px;
  }
  .dis_num2 {
    width: 55px;
    top: 21%;
    font-size: 12px;
  }
  .dis_num3 {
    width: 55px;
    top: 34%;
    font-size: 12px;
  }
  .dis_num4 {
    width: 55px;
    top: 34%;
    font-size: 12px;
  }
  .dis_num5 {
    width: 55px;
    bottom: 20%;
    font-size: 12px;
  }
}
@media screen and (max-width: 1280px){
  .dis_num1 {
    width: 50px;
    left: 35%;
    top: 20%;
    font-size: 12px;
  }
  .dis_num2 {
    width: 50px;
    top: 20%;
    font-size: 12px;
  }
  .dis_num3 {
    width: 50px;
    top: 33%;
    font-size: 12px;
  }
  .dis_num4 {
    width: 50px;
    top: 33%;
    font-size: 12px;
  }
  .dis_num5 {
    width: 50px;
    bottom: 19%;
    font-size: 12px;
  }
  .level_echart {
    width: 76px;
    height: 117px;
    top: 25%;
    right: 16%;
  }
  .wun {
    width: 75px;
    height: 40px;
    font-size: 16px;
    top: 0%;
    left: 0%;
  }
}
</style>
