<template>
  <!-- 客服告警列表 -->
  <div class="box100">
    <div class="flexCB">
      <div class="alarm_box">
        <el-input  placeholder="请输入搜索内容" class="contInput" v-model="ectname"></el-input>
        <!-- <el-select v-model="alatime" placeholder="核警时长" class="utG setSe">
          <el-option
            v-for="item in alatimeOption"
            :key="item.id"
            :label="item.value"
            :value="item.id">
          </el-option>
        </el-select> -->
        <el-select v-model="equtype" placeholder="设备类型" class="utN setSe">
          <el-option
            v-for="item in equtypeOption"
            :key="item.equtypeid"
            :label="item.equtypename"
            :value="item.equtypeid">
          </el-option>
        </el-select>
        <el-select v-model="hejio" placeholder="核警状态" class="utF setSe">
          <el-option
            v-for="item in hejioOption"
            :key="item.id"
            :label="item.value"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button class="searchbtn" @click="handleSearch">搜索</el-button>
        <el-button class="resetbtn" @click="handleReset">重置</el-button>
      </div>
      <div class="cont_box">
        <div class="cont_top">
          <div class="er_item1" @click="tabClick('0')" :class="{'selected': alatype === '0'}">全部</div>
          <div class="er_item2" @click="tabClick('1')" :class="{'selected': alatype === '1'}">火警信息</div>
          <div class="er_item3" @click="tabClick('2')" :class="{'selected': alatype === '2'}">故障</div>
          <div class="er_item4" @click="tabClick('3')" :class="{'selected': alatype === '3'}">隐患</div>
          <div class="er_item5" @click="tabClick('4')" :class="{'selected': alatype === '4'}">其他</div>
        </div>
      </div>
    </div>
    <div class="alarm_table hidTable">
      <el-table
        height="400"
        class="al_table"
        :data="custList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          label="告警编号"
          prop="ectpid"
          width="200">
        </el-table-column>
        <el-table-column
          label="告警内容"
          prop="ectpmark"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="设备类型"
          prop="equname">
        </el-table-column>
        <el-table-column
          label="告警类型"
          prop="ishrisk,ectptypename">
          <template slot-scope="scope">
            <span v-if="scope.row.ishrisk == '1'" class="gwtype">{{scope.row.ectptypename}}</span>
            <span v-if="scope.row.ishrisk != '1'">{{scope.row.ectptypename}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="告警时间"
          prop="ectptime"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="告警地点"
          prop="adderss"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="核警时长"
          prop="alatime">
        </el-table-column>
        <el-table-column
          label="核警状态"
          prop="istice">
          <template slot-scope="scope">
            <div v-if="scope.row.istice == 1" class="one">待核实</div>
            <div v-if="scope.row.istice == 2" class="two">待二次核实</div>
            <div v-if="scope.row.istice == 3" class="three">待处理</div>
            <div v-if="scope.row.istice == 4" class="four">暂不处理</div>
            <div v-if="scope.row.istice == 5" class="five">延时处理</div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" style="color: #42EEFF;" @click="handleDetail(scope.row)">详情</el-button>
            <el-button type="text" size="small" style="color: #42EEFF;" @click="handleNuclear(scope.row)">核警处理</el-button>
            <!-- <el-button type="text" size="small" style="color: #42EEFF;" @click="handleReport(scope.row)">生成报告</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="indexPage">
    </el-pagination>
  </div>
</template>

<script>
import { alarmList, nuclearLong, nuclearState } from '@/http/api/business'
import { getEquipType } from '@/http/api/equip'
export default {
  props: ['unitId', 'type', 'Refresh'],
  name: 'anAlarm',
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      alatype: '1',
      ectname: '',
      // alatime: '',
      // alatimeOption: [],
      equtype: '',
      equtypeOption: [],
      hejio: '',
      hejioOption: [],
      custList: [],
    }
  },
  watch: {
    unitId (val) {
      this.companyid = val
      this.init()
    },
    type (val) {
      this.alatype = val
      this.init()
    },
    Refresh (val) {
      this.init()
    }
  },
  created () {
    this.alatype = this.type
    this.init()
    this.getInit()
  },
  methods: {
    init () {
      const params = {
        companyid: this.unitId,
        ectname: this.ectname,
        equtype: this.equtype,
        hejio: this.hejio,
        alatype: this.alatype,
        // alatime: this.alatime,
        page_index: this.page,
        page_size: this.size
      }
      alarmList(params).then(res => {
        if (res.status === '1') {
          this.custList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.custList = []
          this.total = 0
        }
      })
    },
    getInit () {
      // 设备类型
      getEquipType().then(res => {
        if (res.status === '1') { this.equtypeOption = res.data.data }
        else { this.equtypeOption = [] }
      })

      // 核警时长
      // nuclearLong().then(res => {
      //   if (res.status === '1') { this.alatimeOption = res.data }
      //   else { this.alatimeOption = [] }
      // })

      // 核警状态
      nuclearState().then(res => {
        if (res.status === '1') { this.hejioOption = res.data }
        else { this.hejioOption = [] }
      })
    },
    tabClick (index) {
      this.alatype = index
      this.init()
    },
    // 详情
    handleDetail (row) {
      this.$emit('showEquDetail', row)
    },
    //  核警处理
    handleNuclear (row) {
      this.$emit('showAlarmDetail', row)
    },
    // 生成报告
    handleReport (row) {

    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.ectname = ''
      this.equtype = ''
      this.hejio = ''
      // this.alatime= ''
      // this.alatype = ''
      this.init()
    },
  }
}
</script>

<style scoped>
.alarm_box {
  margin-left: 20px;
}
.alarm_table {
  margin-top: 20px;
}
.alarm_box  >>> .el-range-editor.el-input__inner {
  padding: 0 10px;
}
.alarm_box >>> .el-scrollbar {
  color: #A5B7CC;
}
.hidTable {
  padding: 0 20px;
}
.al_table >>> .el-table__body-wrapper {
  scrollbar-width: none;
}
.al_table >>> .el-table__body-wrapper::-webkit-scrollbar {
  width: 0px;
}
.el-table__body-wrapper::-webkit-scrollbar {
	width: 0;
}
.cont_box {
  margin-right: 20px;
}
.utF {
  margin: 0 16px;
}
.indexPage {
  padding-right: 20px;
}
.searchbtn {
  margin-left: 16px;
}
.contInput .el-input__inner{
  background: rgba(136,172,252,0.16);
  border: none;
  color: #A5B7CC;
}
.cont_top{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}
.er_item1 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
  border-radius: 20px 0px 0px 20px;
}
.er_item2, .er_item3, .er_item4 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
}
.er_item2 {
  margin: 0 2px;
}
.er_item3, .er_item4 {
  margin-right: 2px;
}
.er_item5 {
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  background: rgba(136,172,252,0.16);
  border-radius: 0px 20px 20px 0px;
}
.selected {
  color: #FFAD42;
}
.one, .three {
  width: 62px;
  height: 29px;
  opacity: 1;
  border-radius: 4px;
  color: #4584F9;
  background: rgba(6, 26, 52, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 3px 10px;
  box-sizing: border-box;
}
.two {
  color: #29CB85;
  width: 90px;
  height: 29px;
  background: rgba(6, 26, 52, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 4px;
  padding: 3px 10px;
  box-sizing: border-box;
}
.four, .five {
  color: #FF944C;
  width: 76px;
  height: 29px;
  background: rgba(6, 26, 52, 0.4);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 4px;
  padding: 3px 10px;
  box-sizing: border-box;
}
.gwtype {
  color: #D930EC;
}
</style>
